import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {closeModal} from "../../../../storeManager/modal/actions";
import {connect} from "react-redux";
import AddOrEditUser from '../AddOrEditUser';

class AdminEditUserModal extends Component {

    render() {
        const {modalParameters: {userData, editAdminUser, editUser}} = this.props;
        return (
            <div className="wrapper">
            <div className="row mrl0 justify-content-center">
                <div className="col-12 col-xl-12 prl0 addNewUser">
                    <h2>Edit User</h2>                  

            <AddOrEditUser userData={userData} editAdminUser={editAdminUser} editUser={editUser}/>
            </div>
            </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(AdminEditUserModal));
