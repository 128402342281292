import React, { Component } from "react";
import PropTypes from "prop-types";

class ReCaptcha extends Component {
  constructor(props) {
    super(props);

    this.loadRecaptcha = this.loadRecaptcha.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (document.readyState === "complete") {
      // Window was already loaded (the component is rendered later on)
      // ReCaptacha can be safely loaded
      this.loadRecaptcha();
    } else {
      // Wait that the window gets loaded to load the ReCaptcha
      window.onload = this.loadRecaptcha;
    }
  }
  loadRecaptcha() {
    const { id, apiKey, theme, getRef, resetId } = this.props;
    this.recatchaElt = window.grecaptcha.render(id, {
      sitekey: apiKey || "6LcHQ-sUAAAAADAsbgB-bTaKWa0xz8gaGKLMwfey", //v2 prod 6LceEc0UAAAAAP5BcJZZ4KYV70NjJlP-2Ip5EpVR
      // sitekey: apiKey || "6LcOEs0UAAAAAKXf7nx0xcUfovcXV-edM-G2R8kf", //v3 prod
      // sitekey: apiKey || "6LeMycwUAAAAANFD9JVRFWrhRXX642wzTPHzz4U9", //v2
      //sitekey: apiKey || "6LcK6csUAAAAAJ6_8v9vI66a5UV8TjhnGXXOrogI", //v3
      theme,
      callback: this.handleChange,
      "expired-callback": this.handleChange,
      "error-callback": this.handleChange,
      size: "invisible",
      render: "explicit",
    });
    getRef(window.grecaptcha);
    resetId(this.recatchaElt);
  }

  handleChange(token) {
    const { onChange } = this.props;
    onChange(token);
  }

  render() {
    const { id } = this.props;

    return <div id={id} />;
  }
}

ReCaptcha.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(["dark", "light"])
};

ReCaptcha.defaultProps = {
  theme: "light"
};

export default ReCaptcha;
