import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {PATHS} from "../../../constants/index";
import {MODALS} from '../../../storeManager/modal/constants';
import './connection.scss';

const Connection = (props) => {
  const {
    data,
    serial,
    // locationImage,
    showHideAccordion,
    refreshConnections,
    modifyPackRedirect,
    openModal,
    rechargeRedirect,
    connectionLength
  } = props;
  const connectionSerial = `Connection ${serial + 1}`;
  // const refresh = localStorage.getItem("refresh") ? atob(localStorage.getItem("refresh")) : "true";
  return (
    <div className="connection-accordion">
      <div className={data.isShow ? 'default-content show' : 'default-content'}>
        {/*<img src={locationImage} alt="Hotel" className="image"/>*/}
        <div className="details">
          <div className="title">
            {`${connectionSerial} - `}
            {
              data.accountStatus && (
                <span
                  className={data.accountStatus === "Temp Suspension" ? "status-temp-suspension" : `status-${data.accountStatus.toLowerCase()}`}
                >
                  {data.accountStatus}
                </span>
              )
            }
          </div>
          <div className="name">
            {data.name}
          </div>
          <div className="id-title">
            Subscriber ID
          </div>
          <div className="id-info">
            {data.subscriberId}
            {
              data.hardReset ? (
                <span
                  className={`refresh ml30`}
                  onClick={refreshConnections(data.subscriberId)}
                >
                <i className="refresh-icon">
                    <svg width="13" height="17">
                      <path fill={data.hardReset ? '#0092ed' : '#ccc'} fillRule="evenodd"
                            d="M.877 9.175a6.321 6.321 0 0 1 .198-2.423c.711-2.515 2.939-4.364 5.583-4.397V.712c0-.092.11-.141.182-.085l3.259 2.485c.054.042.054.127 0 .166L6.843 5.764c-.075.055-.182.006-.182-.085V4.038C4.923 4.068 3.439 5.18 2.8 6.752a4.52 4.52 0 0 0 .601 4.458c.29.375.22.923-.154 1.207a.797.797 0 0 1-1.125-.163A6.158 6.158 0 0 1 .877 9.175zm9.411-3.157a4.44 4.44 0 0 1 .93 2.521 4.535 4.535 0 0 1-.33 1.937c-.638 1.572-2.121 2.688-3.86 2.714v-1.64c0-.092-.109-.141-.182-.085L3.587 13.95c-.054.042-.054.127 0 .166l3.256 2.485c.076.056.182.007.182-.084v-1.644c2.643-.029 4.876-1.879 5.583-4.397a6.38 6.38 0 0 0 .197-2.423 6.112 6.112 0 0 0-1.241-3.079.798.798 0 0 0-1.125-.163.865.865 0 0 0-.151 1.207z"/>
                    </svg>
                </i>
                  Heavy Refresh
            </span>
              ) : (
                <span
                  className={`refresh ml30 refresh-prohibited`}
                >
                 Your request for heavy refresh has been accepted
            </span>
              )
            }
          </div>
        </div>
        <span className="show-hide-action" onClick={() => (showHideAccordion(serial))}>
          <i className="icon">
            <svg width="14" height="8">
              <path fill="#77818E" fillRule="evenodd"
                    d="M6.993 8.001c-.315.015-.58-.147-.806-.371C4.253 5.703 2.322 3.773.391 1.843.077 1.529-.09 1.162.048.716.253.047 1.039-.214 1.614.2c.432.311.772.724 1.147 1.096a462.728 462.728 0 0 1 3.961 3.965c.227.231.355.211.572-.008 1.586-1.6 3.183-3.188 4.778-4.779.27-.269.555-.495.974-.472.744.042 1.201.857.831 1.503a2.042 2.042 0 0 1-.352.434L7.927 7.527c-.253.253-.519.485-.934.474z"/>
            </svg>
          </i>
        </span>
      </div>

      <div className="action-content pt15">
        <div className="action-type" onClick={() => openModal(
          MODALS.BALANCE_DETAILS,
          { connectionSerial, subscriberId: data.subscriberId, connectionLength }
        )}
        >
          <i className="action-icon">
            <svg width="18" height="19">
              <path fill="#0092ED" fillRule="evenodd"
                    d="M2.25 18.529H15a.75.75 0 0 0 .75-.75V14.78h1.5a.75.75 0 0 0 .75-.75v-3.749a.75.75 0 0 0-.75-.749h-1.5V6.533a.75.75 0 0 0-.75-.75h-.75V2.784a.75.75 0 0 0-.75-.75H8.379L6.429.669a.75.75 0 0 0-1.08.24l-.641 1.125H2.625A2.624 2.624 0 0 0 0 4.658c0 .086.017.167.024.249A.931.931 0 0 0 0 5.033V16.28a2.25 2.25 0 0 0 2.25 2.249zm14.25-5.248h-3.375a1.125 1.125 0 0 1 0-2.25H16.5v2.25zm-3.375-3.749a2.624 2.624 0 1 0 0 5.248h1.125v2.25h-12a.75.75 0 0 1-.75-.75V7.02c.351.17.735.26 1.125.262H14.25v2.25h-1.125zm-.375-5.999v1.56l-2.228-1.56h2.228zM6.244 2.369l4.877 3.414H4.292l1.952-3.414zM2.625 3.533h1.226l-1.282 2.25a1.125 1.125 0 1 1 .056-2.25z"/>
            </svg>
          </i>
          Balance Details
        </div>
        <div className="action-type" onClick={rechargeRedirect(data.subscriberId)}>
          <i className="action-icon">
            <svg width="11" height="17">
              <path fill="#0092ED" fillRule="evenodd"
                    d="M9.64 2.194h-.014c-.329.004-.66 0-.99 0H6.995c.024.04.059.072.082.112.264.459.439.977.545 1.513H9.64c.249 0 .472.119.651.308.182.189.26.471.271.742.009.26-.111.559-.271.741a.893.893 0 0 1-.651.308h-.014c-.329.004-.66 0-.99 0H7.632a5.539 5.539 0 0 1-.39 1.191 4.662 4.662 0 0 1-1.447 1.779 3.836 3.836 0 0 1-2.061.739l.892.943 1.621 1.716 1.345 1.424.56.593c.18.192.26.47.27.741.011.28-.109.543-.27.742a.882.882 0 0 1-.652.308c-.244 0-.475-.121-.652-.308l-.01-.011c-.256-.267-.508-.537-.761-.806L4.54 13.343c-.569-.604-1.138-1.205-1.707-1.807l-1.286-1.361-.295-.311a1.165 1.165 0 0 1-.3-.786c-.002-.162 0-.325 0-.487 0-.569.424-1.05.922-1.05h1.273c.227 0 .453-.005.679-.026.213-.04.42-.105.622-.192.186-.099.364-.217.531-.353.149-.143.287-.3.412-.47a3.85 3.85 0 0 0 .3-.582H1.485c-.249 0-.472-.119-.651-.308-.182-.189-.26-.47-.271-.741-.009-.261.111-.56.271-.742a.893.893 0 0 1 .651-.308h.014c.329-.003.66 0 .99 0h3.203a4.023 4.023 0 0 0-.301-.584 3.63 3.63 0 0 0-.412-.469 3.36 3.36 0 0 0-.531-.354 2.987 2.987 0 0 0-.633-.195c-.129-.011-.258-.023-.387-.023H1.485c-.249 0-.472-.119-.651-.307-.182-.189-.26-.471-.271-.742C.554.884.674.585.834.403a.895.895 0 0 1 .651-.307H9.64c.249 0 .472.119.651.307.182.189.26.471.271.742.009.261-.111.56-.271.742a.895.895 0 0 1-.651.307z"/>
            </svg>
          </i>
          Recharge
        </div>

        <div className="action-type edit" onClick={modifyPackRedirect(data.subscriberId, data.name)}>
          <i className="action-icon">
            <svg width="15" height="15">
              <path fill="#0293ED" fillRule="evenodd"
                    d="M14.527 2.463L12.566.503c-.56-.56-1.47-.56-1.96 0L.943 10.096v3.992h3.991l9.593-9.664c.771-.77.28-1.68 0-1.961zM4.304 12.687H2.343v-1.96l7.282-7.283 1.961 1.961-7.282 7.282zm8.262-8.263l-1.96-1.961.98-.98 1.961 1.961-.981.98z"/>
            </svg>
          </i>
          Modify Pack
        </div>

        <div className="action-type">
          <i className="action-icon">
            <svg width="19" height="15">
              <path fill="#0092ED" fillRule="evenodd"
                    d="M1.497 10.656c-.395-.177-.631-.549-.599-.943l.319-4.126c.088-.533.641-.901 1.236-.822.531.07.926.476.93.956l-.134 1.778L12.353.403c.454-.353 1.141-.311 1.536.095s.348 1.022-.106 1.376L2.696 10.516a1.19 1.19 0 0 1-1.199.14zM18.904 4.61l-.319 4.127c.005.538-.478.978-1.08.982-.601.005-1.092-.428-1.097-.967a.842.842 0 0 1 .011-.15l.134-1.778-9.104 7.096c-.453.354-1.141.311-1.536-.095s-.347-1.022.106-1.375l11.087-8.643a1.192 1.192 0 0 1 1.2-.139c.394.176.63.548.598.942z"/>
            </svg>
          </i>

          <Link
            to={{
              pathname: PATHS.TRANSACTION_HISTORY,
              state: { subscriberId: data.subscriberId, noOfRows: connectionLength }
            }}>
            Transaction History
          </Link>
        </div>
        <div className="action-type">
          <i className="action-icon">
            <svg width="18" height="18">
              <path fill="#0092ED" fillRule="evenodd"
                    d="M16.01 13.591l.945 3.422a.76.76 0 0 1-.166.709.836.836 0 0 1-1.149.091l-4.78-3.871H3.565C1.597 13.94.002 12.406 0 10.513V3.429C.002 1.536 1.597.002 3.565-.001h10.87c1.968.003 3.563 1.537 3.565 3.43v7.084c-.001 1.307-.772 2.5-1.99 3.078zm.59-10.162c-.001-1.15-.97-2.082-2.165-2.083H3.565c-1.195.001-2.164.933-2.165 2.083v7.084c.001 1.15.97 2.081 2.165 2.083h7.805l3.757 3.041-.812-2.939.677-.172c.948-.243 1.608-1.069 1.608-2.013V3.429zm-3.079 4.94c-.725 0-1.313-.565-1.313-1.263 0-.697.588-1.263 1.313-1.263s1.313.566 1.313 1.263c0 .698-.588 1.263-1.313 1.263zm-4.602 0c-.725 0-1.313-.565-1.313-1.263 0-.697.588-1.263 1.313-1.263s1.313.566 1.313 1.263c0 .698-.588 1.263-1.313 1.263zm-4.602 0c-.725 0-1.313-.565-1.313-1.263 0-.697.588-1.263 1.313-1.263s1.313.566 1.313 1.263c0 .698-.588 1.263-1.313 1.263z"/>
            </svg>
          </i>
          <Link
              to={{
                pathname: PATHS.SERVICE_REQUEST,
                state: {
                  subId: data.subscriberId,
                  noOfRows: connectionLength
                }

              }}
          >
            Raise A Service Request
          </Link>
        </div>
        <div className="suspend-text">
          Do you wish to temporarily
          <span className="link prl5">
            {
              data.accountStatus === "Temp Suspension" ?
                (
                  <Link to={{
                    pathname: PATHS.TEMPORARILY_SUSPEND,
                    state: { subscriberId: data.subscriberId, actionType: "Resume", noOfRows: connectionLength }
                  }}>
                    resume
                  </Link>)
                :
                (
                  <Link to={{
                    pathname: PATHS.TEMPORARILY_SUSPEND,
                    state: { subscriberId: data.subscriberId, actionType: "Suspend", noOfRows: connectionLength }
                  }}>
                    suspend
                  </Link>
                )
            }
          </span>
          any TV under this connection?
        </div>

      </div>
    </div>
  )
};

Connection.propTypes = {
  serial: PropTypes.number,
  data: PropTypes.object,
  // imageImage: PropTypes.string,
  showHideAccordion: PropTypes.func,
};

Connection.defaultProps = {
  serial: 0,
  data: {},
  showHideAccordion: () => {
  },
};

export default Connection;
