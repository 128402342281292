import axios from "axios";

export const serialize = (obj) => {
  const str = [];
  Object.keys(obj).forEach(p => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  /*for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }*/
  return str.join('&');
};

export const replaceStringWithParams = (str, obj) => {
  Object.keys(obj).forEach(j => {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  });
  /*for (const j in obj) {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  }*/
  return str;
};

export const isAPICanceled = (e) => {
  if(axios.isCancel(e)){
    return false;
  }
};

export const setLocalStorage = (key, value) =>{
  localStorage.setItem(key, btoa(JSON.stringify( value)));
}

export const getLocalStorage = (key) =>{
  const localStorageData = localStorage.getItem(key) && JSON.parse(atob(localStorage.getItem(key)))
  return localStorageData;
}