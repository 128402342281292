import { format, getTime } from 'date-fns';

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

export const isAdminAuthenticated = () => {
  const token = localStorage.getItem("token");
  const admin = localStorage.getItem("admin");
  if(token && admin){
    return true
  }
  return false;
};

export const getCheckedListNCountObj = (array, value) => {
  const updatedDataList = [...array];
  let checkedCount = 0;
  updatedDataList.forEach(currentObj => {
    if (+currentObj.subscriberId === +value) {
      currentObj.isChecked = !currentObj.isChecked;
    }
    if (currentObj.isChecked) {
      checkedCount += 1;
    }
  });
  return {
    updatedDataList, checkedCount
  }
};

export const dateFormatter = (utcTime) => {
  /*const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dateObj = new Date(utcTime);
  const d = dateObj.getDate();
  const m = dateObj.getMonth();
  const mn = monthNames.filter((name, i)=> i===m);
  const y = dateObj.getFullYear();
  if(d<10){
    return '0'+d +' '+ mn +' ' + y;
  } else return d +' '+ mn +' ' + y;*/
  return format(new Date(utcTime), 'dd MMM yyyy'); //09 Dec 2019
};

export const dateTimeFormatter = (utcTime) => {
  return format(new Date(utcTime), 'dd MMM yyyy, hh:mm a'); //09 Dec 2019, 10:09 AM
};

export const getTimeStamp = (date) => {
  return getTime(new Date(date));
};

export const getDateFormatYYYYMMDD = (date) => {
  const dateObj = new Date(date);
  const d = dateObj.getDate();
  const m = dateObj.getMonth() + 1;
  const y = dateObj.getFullYear();
  return y +'-'+ m +'-' + d
};

export const getCategoryWiseData = (array, categoryKey) => {
  const categoryObj = {};
  array.forEach((elem) => {
    const currentCategory = elem[categoryKey];
    if (!categoryObj.hasOwnProperty(currentCategory)) {
      categoryObj[currentCategory] = [];
    }
    categoryObj[currentCategory].push(elem);
  });
  return categoryObj;
};

export const restrictDecimalInput = (e) => {
  if (e.charCode === 46) e.preventDefault();
};

export const sortBy = (array, key, order, type) => {
  let sortedArray = [...array];
  if(type === "number") {
    sortedArray.sort((a, b) => (order === "dsc") ? b[key]-a[key] : a[key]- b[key]);
  } else {
    sortedArray.sort((a, b) => {
      if (order === "dsc") {
        return a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0;
      } else {
        return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
      }
    });
  }
  return sortedArray;
};

export const sortByObjValue = (array, key, order) => {
  let sortedArray = [...array];
  sortedArray.sort((a, b) => {
    if (order === "dsc") {
      return (a[key].props.children > b[key].props.children) ? -1 : (a[key].props.children < b[key].props.children) ? 1 : 0;
    } else {
      return (a[key].props.children < b[key].props.children) ? -1 : (a[key].props.children > b[key].props.children) ? 1 : 0;
    }
  });
  return sortedArray;
};

export const getSearchResult = (array, key, value) => {
  let count = value.length <= 3 ? 1 : 0;
  array.forEach(obj=>{
    if(value.length > 3) {
      obj.isVisible = obj[key].includes(value);
      obj.isVisible && (count++);
    } else {
      obj.isVisible = true
    }
  });
  return {data: array, noDataFound: !count };
};