import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {closeModal, openModal as openModalHandler} from "../../../../../storeManager/modal/actions";
import {commonService} from "../../../../../storeManager/common/services";
import GlobalConfig from "../../../../../globalConfig/globalConfig";
import Loader from "../../../../Loader";
import Error from "../../../../Common/Error";
import {restrictDecimalInput} from '../../../../../helper';

class OTPModal extends Component {
    state = {
        otp: '',
        error: '',
        errorMsg: {},
        isLoading: false,
        resendOtp: true,
        showMsg: false,
    };

    componentDidMount() {
        this.inputFocus.focus();
    }

    adminInputChangeHandler = (event) => {
        let {name, value} = event.target;
        if (name === 'otp') {
            if (!isNaN(value)) {
                this.setState({
                    [name]: value,
                    errorMsg: {}
                })
            }
        }
    };

    validateOTP = async () => {
        const {otp} = this.state;
      const { modalParameters:{chainId, newPassword}} = this.props;

      const payload = {
          "newPassword": newPassword,
          "otp": otp,
          "chainId": chainId, 
      };
      try {
        const response = await commonService.resetUserPassword(payload);
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
            this.setState({showMsg:true, successMsg: responseData.message, isLoading: false})
        } else {
          this.setState({error: responseData.message, isLoading: false})
        }
      } catch {
        this.setState({error: GlobalConfig.serverError, isLoading: false})
      }
    };


    generateOtpForRMN = async () => {
        this.setState({error: '', isLoading: true, otp: ''});
        try {
            const response = await commonService.resetUserPasswordOtp();
            const responseData = response.data;
            this.setState({error: responseData.message, isLoading: false})
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }

    };

    verifyUser = (e) => {
        e.preventDefault();
        const { showEmailOrPhoneField } = this.state;
      this.setState({isLoading: true});
      if(showEmailOrPhoneField){
          this.generateOtpForRMN();
      }  else {
        this.validateOTP();
      }
    };

    render() {
        const {otp, error, isLoading, showMsg, successMsg} = this.state;
        const { modalParameters:{message} } = this.props;
        const {adminInputChangeHandler, verifyUser, generateOtpForRMN} = this;
        const OTPLink = (
            <div className="text-btn ptb15" onClick={generateOtpForRMN}>
              Resend OTP
            </div>
        );
        
        if(showMsg){
            return (<div className="b2b-modal-header center pb20">
            <div className="gradient-text">
            {successMsg}
            </div>
        </div>)
        }

        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <div className="b2b-modal-header center pb20">
                    <div className="gradient-text">
                    {message}
                    </div>
                </div>

                <div className="prl50" style={{textAlign:'center'}}>
                    <form onSubmit={(e) => verifyUser(e)}>
                            <Fragment>
                
                            <div className="form-element">
                                <input type="text"
                                        className="input-element otp-pwd"
                                        placeholder="OTP"
                                        maxLength="6"
                                        name="otp"
                                        value={otp}
                                        onChange={adminInputChangeHandler}
                                        ref={(input) => {
                                        this.inputFocus = input;
                                        }}
                                        autoComplete="off"
                                        onKeyPress={restrictDecimalInput}
                                />
                            </div>
                            {OTPLink}
                            </Fragment>

                        {
                            error && <Error error={error}/>
                        }

                        <button
                            type="submit"
                            className="button fill big w-140 mt10 mb20"
                            onClick={(e) => verifyUser(e)}
                            disabled={(!otp || otp.length < 6)}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(OTPModal));
