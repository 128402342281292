import {AxiosService} from '../../services/apiService';

import {
  validateRmnChainIdApi,
  generateOtpApi,
  validateOTPApi,
  validatePasswordApi,
  getSubscriberListApi,
  getBalanceDetailApi,
  redirectModifyPackApi,
  getTransactionsApi,
  getHardResetApi,
  getSuspendStatusApi,
  getSuspendResumeStatusApi,
  getSRTypePopulateApi,
  getSRSubTypePopulateApi,
  getServiceRequestApi,
  getServiceRequestQueryApi,
  getModifyPack,
  getSubscriberListApiWithChainId,
  getRechargeUrlApi,
  registerUserApi,
  bulkRechargeListApi,
  getBulkModifySubmitApi,
  recentActivitiesApi,
  getActivityDetailApi,
  getActivitiesDetailConfirmationApi,
  escalationMatrixListApi,
  changePasswordApi,
  sendEmailApi,
  viewProfileApi,
  clearHardResetApi,
  getChainIdListApi,
  bulkRechargeStatusApi,
  bulkRechargeTransactionStatusApi,
  validateResetPasswordTokenApi,
  submitResetPasswordApi,
  adminLoginApi,
  generateOtpAdminApi,
  validateAdminOTPApi,
  resetUserPasswordApi,
  userListApi,
  addNewUserApi,
  editUserApi,
  resetUserPasswordOtpApi,
} from "./repository";

import GlobalConfig from "../../globalConfig/globalConfig";

class CommonService {
  validateRmnChainId(param) {
    validateRmnChainIdApi.params = param;
    const api = new AxiosService(validateRmnChainIdApi);
    return api.doAjax();
  }

  generateOtp(param) {
    generateOtpApi.params = param;
    const api = new AxiosService(generateOtpApi);
    return api.doAjax();
  }

  validateOTPStatus(param) {
    validateOTPApi.params = param;
    const api = new AxiosService(validateOTPApi);
    return api.doAjax();
  }

  validatePasswordStatus(param) {
    validatePasswordApi.params = param;
    const api = new AxiosService(validatePasswordApi);
    return api.doAjax();
  }

  subscriberListApi(param) {
    getSubscriberListApi.params = param;
    const api = new AxiosService(getSubscriberListApi);
    return api.doAjax();
  }

  balanceDetailApi(param) {
    getBalanceDetailApi.params = param;
    const api = new AxiosService(getBalanceDetailApi);
    return api.doAjax();
  }

  redirectModifyPackApi(param) {
    redirectModifyPackApi.params = param;
    const api = new AxiosService(redirectModifyPackApi);
    return api.doAjax();
  }


  transactionHistoryApi(param) {
    getTransactionsApi.params = param;
    const api = new AxiosService(getTransactionsApi);
    return api.doAjax();
  }

  hardResetApi(param) {
    getHardResetApi.params = param;
    const api = new AxiosService(getHardResetApi);
    return api.doAjax();
  }

  suspendStatusApi(param) {
    getSuspendStatusApi.params = param;
    const api = new AxiosService(getSuspendStatusApi);
    return api.doAjax();
  }

  suspendResumeStatusApi(param) {
    getSuspendResumeStatusApi.params = param;
    const api = new AxiosService(getSuspendResumeStatusApi);
    return api.doAjax();
  }

  populateSRTypeApi() {
    getSRTypePopulateApi.url = `${GlobalConfig.API_ROOT}/serviceRequest/srTypePopulate?srRequestType=${'SubId'}`;
    const api = new AxiosService(getSRTypePopulateApi);
    return api.doAjax();
  }

  populateSRSubTypeApi(param) {
    getSRSubTypePopulateApi.params = param;
    const api = new AxiosService(getSRSubTypePopulateApi);
    return api.doAjax();
  }

  createServiceRequestApi(param) {
    getServiceRequestApi.params = param;
    const api = new AxiosService(getServiceRequestApi);
    return api.doAjax();
  }

  serviceRequestQueryApi(param) {
    getServiceRequestQueryApi.params = param;
    const api = new AxiosService(getServiceRequestQueryApi);
    return api.doAjax();
  }

  getServicePackRequestApi() {
    const api = new AxiosService(getModifyPack);
    return api.doAjax();
  }

  getSubscriberListWithChainId(param) {
    getSubscriberListApiWithChainId.params = param;
    const api = new AxiosService(getSubscriberListApiWithChainId);
    return api.doAjax();
  }

  redirectRechargeApi(subId) {
    getRechargeUrlApi.url = `${GlobalConfig.API_ROOT}/redirect/rechargeRequest?subscriberId=${subId}`;
    const api = new AxiosService(getRechargeUrlApi);
    return api.doAjax();
  }

  registerUserApi(param) {
    registerUserApi.params = param;
    const api = new AxiosService(registerUserApi);
    return api.doAjax();
  }

  bulkModifySubmitApi(param) {
    getBulkModifySubmitApi.params = param;
    const api = new AxiosService(getBulkModifySubmitApi);
    return api.doAjax();
  }

  getBulkRechargeList(param) {
    bulkRechargeListApi.params = param;
    const api = new AxiosService(bulkRechargeListApi);
    return api.doAjax();
  }

  recentActivitiesApi(param) {
    recentActivitiesApi.params = param;
    const api = new AxiosService(recentActivitiesApi);
    return api.doAjax();
  }

  activitiesDetailsApi(param) {
    getActivityDetailApi.params = param;
    const api = new AxiosService(getActivityDetailApi);
    return api.doAjax();
  }

  activitiesDetailConfirmationApi(param) {
    getActivitiesDetailConfirmationApi.params = param;
    const api = new AxiosService(getActivitiesDetailConfirmationApi);
    return api.doAjax();
  }

  getEscalationMatrixList() {
    const api = new AxiosService(escalationMatrixListApi);
    return api.doAjax();
  }

  submitChangePassword(param) {
    changePasswordApi.params = param;
    const api = new AxiosService(changePasswordApi);
    return api.doAjax();
  }

  sendEmailStatus(param) {
    sendEmailApi.params = param;
    const api = new AxiosService(sendEmailApi);
    return api.doAjax();
  }

  viewProfileStatus() {
    const api = new AxiosService(viewProfileApi);
    return api.doAjax();
  }

  clearHardResetStatus() {
    const api = new AxiosService(clearHardResetApi);
    return api.doAjax();
  }

  chainIdListApi(param) {
    getChainIdListApi.params = param;
    const api = new AxiosService(getChainIdListApi);
    return api.doAjax();
  }

  getBulkRechargeStatus(param) {
    bulkRechargeStatusApi.params = param;
    const api = new AxiosService(bulkRechargeStatusApi);
    return api.doAjax();
  }

  getBulkRechargeTransactionStatus(txnId) {
    bulkRechargeTransactionStatusApi.url = `${GlobalConfig.API_ROOT}/billdesk/getTransactionStatus/${txnId}`;
    const api = new AxiosService(bulkRechargeTransactionStatusApi);
    return api.doAjax();
  }

  validateResetPasswordToken(param) {
    validateResetPasswordTokenApi.url = `${GlobalConfig.API_ROOT}/resetToken/validate?resetToken=${param.token}`;
    const api = new AxiosService(validateResetPasswordTokenApi);
    return api.doAjax();
  }

  submitResetPassword(param) {
      submitResetPasswordApi.params = param;
    const api = new AxiosService(submitResetPasswordApi);
    return api.doAjax();
  }

  adminLogin(param) {
    adminLoginApi.params = param;
  const api = new AxiosService(adminLoginApi);
  return api.doAjax();
}
  generateOtpAdmin(param) {
    generateOtpAdminApi.params = param;
    const api = new AxiosService(generateOtpAdminApi);
    return api.doAjax();
  }

  validateAdminOTPStatus(param) {
    validateAdminOTPApi.params = param;
    const api = new AxiosService(validateAdminOTPApi);
    return api.doAjax();
  }

  resetUserPasswordOtp(param){
    resetUserPasswordOtpApi.params = param;
    const api = new AxiosService(resetUserPasswordOtpApi);
    return api.doAjax();
  }

  resetUserPassword(param){
    resetUserPasswordApi.params = param;
    const api = new AxiosService(resetUserPasswordApi);
    return api.doAjax();
  }

  getUserListApi() {
    const api = new AxiosService(userListApi);
    return api.doAjax();
  }

  addNewUser(param){
    addNewUserApi.params = param;
    const api = new AxiosService(addNewUserApi);
    return api.doAjax();
  }

  editUser(param){
    editUserApi.params = param;
    const api = new AxiosService(editUserApi);
    return api.doAjax();
  }

}

export const commonService = new CommonService();