import React from 'react';
import {NavLink} from 'react-router-dom';
import {PATHS} from "../../../../constants";
import {getLocalStorage} from '../../../../services/commonUtils';

import './Navbar.scss';

const Navbar = () => {
    const userType = getLocalStorage('admin');
    if(userType && userType.role === 'admin' ){
        return (
            <ul className="navbar">
                <li className="option">
                    <NavLink to={PATHS.ADMIN_DASHBOARD} exact={true}>
                        Dashboard
                    </NavLink>
                </li>
                <li className="option">
                    <NavLink to={PATHS.ADMIN_ADD_USER} exact={true}>
                        Add New User
                    </NavLink>
                </li>
                </ul>
        )
    }

    if(userType && userType.role === 'sales' ){
        return (
            <ul className="navbar">
                <li className="option">
                    <NavLink to={PATHS.ADMIN_DASHBOARD} exact={true}>
                        Change Password
                    </NavLink>
                </li>
             </ul>
        )
    }

    return (
        <ul className="navbar">
            <li className="option">
                <NavLink to={PATHS.HOME} exact={true}>
                    Dashboard
                </NavLink>
            </li>

            <li className="option">
                <NavLink to={PATHS.ESCALATION_MATRIX}>
                    Escalation Matrix
                </NavLink>
            </li>

            <li className="option" title="Coming Soon...">
                <span className="disabled-link">
                    Bulk Recharge
                </span>
            </li>

            {/* <li className="option">
                <NavLink to={PATHS.BULK_RECHARGE}>
                    Bulk Recharge
                </NavLink>
            </li> */}

            <li className="option">
                <NavLink to={PATHS.BULK_MODIFY}>
                    Bulk Modify
                </NavLink>
            </li>

            <li className="option">
                <NavLink to={PATHS.ACTIVITIES}>
                    Transactions {/*Activities*/}
                </NavLink>
            </li>

            <li className="option">
                <NavLink to={PATHS.SERVICE_REQUEST}>
                    Service Request
                </NavLink>
            </li>
        </ul>
    )
};


export default Navbar;