import React, {Component} from 'react';
import {PATHS} from "../../../constants";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {closeModal} from "../../../storeManager/modal/actions";
import {connect} from "react-redux";

class ConfirmationPopup extends Component {

    redirectToHome = () => {
        const {history, closeModal} = this.props;
        closeModal();
        history.push(PATHS.HOME)
    };

    render() {
        const {modalParameters: {message, reDirectLinkHide, redirectLoginPage, showOkCloseButton}, closeModal} = this.props;
        return (
            <div className="text-center ptb30">
                <div className="b2b-modal-header pb35">
                    <div className="gradient-text">
                        {message}
                    </div>
                </div>
                {
                    reDirectLinkHide ?  null : (
                      <button className="button fill" onClick={() => this.redirectToHome()}>
                        {
                          redirectLoginPage ? 'Go to Login' : 'Go to Dashboard'
                        }
                      </button>
                    )
                }
                {
                    showOkCloseButton ? (
                      <button className="button fill" onClick={() => closeModal()}>
                        Ok
                      </button>
                    ) :  null
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(ConfirmationPopup));
