import React, {Component, Fragment} from 'react';
import '../../bulk-recharge.scss';
import Loader from "../../../Loader";
import {getTotalMBR} from "../../../../storeManager/common/transformer";

class EditRechargeTable extends Component {
  state = {
    tableData: [],
    isLoading: true,
    updatedMbr: null
  };

  componentDidMount() {
    const { tableData } = this.props;
    if(tableData) {
      const updateTableData = [...tableData];
      updateTableData.map(obj => {
        return obj.isEdit = false
      });
      this.setState({tableData: updateTableData, isLoading: false});
    }
  }

  handleEditBtn = (index) => {
    const { tableData } = this.state;
    const updateTableData = [ ...tableData ];
    let mbrValue = null;
    updateTableData.map((obj, idx)=>{
      if(idx === index) {
        mbrValue = obj.mbr;
        return obj.isEdit = !obj.isEdit;
      }
      return obj.isEdit = false;
    });
    this.setState({tableData: updateTableData, updatedMbr: mbrValue});
  };

  onchangeEditHandler = (value) => {
    let inputValue = value.replace(/[^0-9]/g, '');
    this.setState({updatedMbr: inputValue});
  };

  confirmChange = (index) => {
    const { updateTotalAmount } = this.props;
    const { tableData, updatedMbr } = this.state;
    const updateTableData = [ ...tableData ];
    updateTableData.map((obj, idx)=>{
      if(idx === index) {
        obj.mbr = updatedMbr || obj.mbr;
        obj.isEdit = !obj.isEdit;
        return obj;
      }
      return null;
    });
    const rechargeTotalAmount = getTotalMBR(updateTableData);
    updateTotalAmount(rechargeTotalAmount);
    /*if(rechargeTotalAmount) {
      updateTotalAmount(rechargeTotalAmount);
    }*/
    this.setState({tableData: updateTableData, updatedMbr: null});
  };

  render() {
    const { tableData, isLoading, updatedMbr } = this.state;
    return (
      <Fragment>
        {
          isLoading && <Loader/>
        }
        <table className="table-wrapper edit-table">
          <thead>
          <tr>
            {/*<th className="pl30">
              Chain Id
            </th>*/}
            <th>
              Subscriber ID
            </th>
            <th className="pr30">
              Recharge Amount
            </th>
          </tr>
          </thead>
          <tbody>
          {
            tableData && tableData.map((item, key) => (
              <tr key={key}>
                {/*<td className="pl30">
                  {item.chainId}
                </td>*/}
                <td>
                  {item.subscriberId}
                </td>
                <td className="pr30 recharge-edit d-flex align-items-center">
                  <span className="pr5">
                    &#8377;
                  </span>
                  {
                    item.isEdit ?
                      <Fragment>
                        <div className="form-element">
                          <input
                            type="text"
                            id={key}
                            value={updatedMbr}
                            className="input-element"
                            onChange={(e)=> this.onchangeEditHandler(e.target.value)}
                          />
                        </div>
                        <i className="confirm-icon ml30" onClick={()=>this.confirmChange(key)}>
                          <svg width="27" height="27">
                            <title>Confirm</title>
                            <path fill="#0092ED" fillRule="evenodd"
                                  d="M13.499.014C6.052.014.013 6.052.013 13.499c0 7.447 6.039 13.486 13.486 13.486 7.447 0 13.486-6.039 13.486-13.486 0-7.447-6.039-13.485-13.486-13.485z"/>
                            <path fill="#FFF" fillRule="evenodd"
                                  d="M21 10.581c-.079.222-.24.391-.42.553-1.034.926-2.061 1.857-3.092 2.785l-3.772 3.392-2.022 1.813c-.508.456-1.08.462-1.592.003-.978-.876-1.95-1.757-2.926-2.636-.58-.522-1.159-1.045-1.745-1.562-.19-.167-.351-.347-.431-.579v-.397c.195-.475.542-.723 1.004-.713.309.007.558.132.773.325.947.856 1.895 1.712 2.844 2.567.38.342.767.68 1.143 1.025.102.094.164.102.272.003a438.8 438.8 0 012.378-2.149c1.311-1.181 2.624-2.361 3.936-3.54.625-.562 1.267-1.108 1.872-1.687.55-.527 1.515-.381 1.757.334.005.013.014.025.021.038v.425z"/>
                          </svg>
                        </i>
                        <i className="close-edit ml10" onClick={() => this.handleEditBtn(key)}>
                          <svg width="34" height="34">
                            <title>Cancel</title>
                            <path fill="#FFF" fillRule="evenodd" stroke="#0092ED"
                                  d="M16.999 3.514c-7.447 0-13.486 6.038-13.486 13.485 0 7.447 6.039 13.486 13.486 13.486 7.447 0 13.486-6.039 13.486-13.486 0-7.447-6.039-13.485-13.486-13.485z"/>
                            <path fill="#0092ED" fillRule="evenodd"
                                  d="M23.306 22.011c-.009.349-.166.615-.481.766-.323.155-.654.133-.919-.113-.441-.407-.859-.839-1.285-1.263a955.508 955.508 0 01-3.181-3.186c-.103-.104-.156-.097-.255.002-1.433 1.44-2.871 2.874-4.306 4.312-.204.203-.427.347-.73.334-.341-.015-.593-.175-.741-.476a.82.82 0 01.08-.89c.058-.08.128-.15.198-.219l4.268-4.27c.137-.137.137-.137.004-.27-1.433-1.432-2.864-2.867-4.3-4.297-.288-.286-.425-.605-.284-1.003a.851.851 0 011.333-.375c.262.213.486.468.725.707 1.252 1.249 2.501 2.5 3.752 3.751.129.129.129.129.26-.002 1.433-1.433 2.867-2.865 4.298-4.3.207-.207.433-.354.742-.339.341.017.591.18.737.482a.82.82 0 01-.079.878c-.059.084-.134.157-.206.23l-4.269 4.269c-.132.133-.132.133-.004.26 1.433 1.433 2.865 2.867 4.3 4.298.199.199.351.416.343.714z"/>
                          </svg>
                        </i>
                      </Fragment>
                      :
                      <Fragment>
                                            <span className="recharge-amount">
                                                {item.mbr}
                                            </span>
                        <i className="edit-icon ml30" id={key} onClick={() => this.handleEditBtn(key)}>
                          <svg width="15" height="15">
                            <title>Edit</title>
                            <path fill="#FFF" fillRule="evenodd"
                                  d="M14.527 2.463L12.566.503c-.56-.56-1.47-.56-1.96 0L.943 10.096v3.992h3.991l9.593-9.664c.771-.77.28-1.68 0-1.961zM4.304 12.687H2.343v-1.96l7.282-7.283 1.961 1.961-7.282 7.282zm8.262-8.263l-1.96-1.961.98-.98 1.961 1.961-.981.98z"/>
                          </svg>
                        </i>
                      </Fragment>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default EditRechargeTable;
