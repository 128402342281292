import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../storeManager/modal/actions";
import {connect} from "react-redux";
import Modal from "./../Common/Modal";
import {MODALS} from "../../storeManager/modal/constants";
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import Loader from "../Loader";
import Error from "../Common/Error";

class MyProfile extends Component {
    state = {
      userProfile: {},
      isLoading: true,
      error: ''
    };

    componentDidMount(){
        this.viewProfile();
    }

  viewProfile = async () => {
    try {
      const response = await commonService.viewProfileStatus();
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({userProfile: responseData.data, isLoading: false})
      } else {
        this.setState({error: responseData.message, isLoading: false})
      }
    } catch {
      this.setState({error: GlobalConfig.serverError, isLoading: false})
    }
  };

    render() {
        const {userProfile, isLoading, error} = this.state;
        const {openModal} = this.props;
        return(
            <div className="my-profile-container">
              {
                  isLoading && <Loader />
              }
                <div className="content-wrapper pb60">
                    <div className="d-flex justify-content-center pb30">
                        <div className="heading">
                            My Profile
                        </div>
                    </div>

                    <div className="user-details ptb20 prl30">
                        <div className="title pb20">
                            Personal Information
                        </div>

                      <div className="row no-gutters mb20">
                        <div className="col-sm-4">
                          Chain ID :
                        </div>
                        <div className="col-sm-8">
                          {userProfile.chainId || "-"}
                        </div>
                      </div>

                        <div className="row no-gutters mb20">
                            <div className="col-sm-4">
                                Name :
                            </div>
                            <div className="col-sm-8">
                                {userProfile.name || "-"}
                            </div>
                        </div>

                        <div className="row no-gutters mb20">
                            <div className="col-sm-4">
                                Mobile Number :
                            </div>
                            <div className="col-sm-8">
                                {userProfile.rmn || '-'}
                            </div>
                        </div>

                        <div className="row no-gutters mb20">
                            <div className="col-sm-4">
                                Email :
                            </div>
                            <div className="col-sm-8">
                                {userProfile.email || '-'}
                            </div>
                        </div>

                        <div className="text-btn" onClick={() => openModal(
                            MODALS.CHANGE_PASSWORD, {message: ''}
                        )}>
                            Change Password
                        </div>

                        <Error error={error}/>
                    </div>
                    <Modal/>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default withRouter(connect(null, mapDispatchToProps)(MyProfile));