import axios from 'axios';
import {resetReduxState} from '../src/storeManager/common/actions';
import {PATHS} from "./constants";
import {get} from "lodash";

export default {
  setupInterceptors: (store, history) => {
    axios.interceptors.request.use(function(config) {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    }, function(err) {
      return Promise.reject(err);
    });

    axios.interceptors.response.use(response => {
      if (get(response, "headers.accesstoken")) {
        localStorage.setItem("token", `Bearer ${get(response, "headers.accesstoken")}`);
      }
      return response;
    }, error => {
      if(error.response && error.response.status === 401){
        localStorage.clear();
        sessionStorage.clear();
        store.dispatch(resetReduxState());
        history.push(PATHS.HOME);
      }
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        history.push(PATHS.SOMETHING_WENT_WRONG);
      }
      if (error.response && error.response.status >= 500) {
        if(error && error.message !== 'Request aborted' && error.message !== 'Operation canceled') {
          history.push(PATHS.SERVER_ERROR);
        }
      }
      return Promise.reject(error);
    });
  },
};