import React, {Component, Fragment} from 'react';
import './bulk-recharge.scss';
import SubscriberDetails from './SubscriberDetails';
import {infiniteScroll, MESSAGES, PLACEHOLDER} from "../../constants";
import Search from "../Common/Search";
// import customerDetails from "../../mocks/selectRecharge";
import {
  getCheckedListNCountObj,
  getSearchResult,
  dateFormatter,
  sortBy,
  sortByObjValue,
  getTimeStamp
} from "../../helper";
import {getTotalMBR} from "../../storeManager/common/transformer";
import Loader from "../Loader";
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import SockJsClient from 'react-stomp';
import ContentLoader from "../Common/ContentLoader";
import ReactLoading from "react-loading";
import {debounce, get} from "lodash";
import Error from "../Common/Error";

class BulkRecharge extends Component {
  bulkRechargeTextArea = React.createRef();
  bulkRechargeInput = React.createRef();
  getThisForm = React.createRef();

  state = {
    isLoading: true,
    loadMoreLoader: false,
    isCheckedAll: false,
    isDisabledCheckedAll: true,
    isSubmitted: false,
    // isRecharge: false,
    showSubmitBtn: false,
    customerDetails: [],
    selectedCustomerDetails: [],
    isTotalEdit: false,
    totalAmount: 1000.00,
    rowsCount: infiniteScroll.bulkModifyRowCount,
    pageCount: infiniteScroll.pageCount,
    hasMoreItems: false,
    searchValue: "",
    noDataFound: false,
    error: "",
    sortDirection: "asc"
  };

  componentDidMount() {
    this.timer = 0;
    /*this.setState(() => ({customerDetails: customerDetails}), () => {
      setTimeout(() => {
        this.setState(() => ({isLoading: false}))
      }, 1000);
    });*/
    this.getBulkRechargeDetail()
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { hasMoreItems, pageCount, noDataFound } = this.state;
    const footerHeight = document.querySelector('footer').offsetHeight;
    if (((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - footerHeight))
      && hasMoreItems && !noDataFound) {
      this.setState({pageCount: pageCount + 1, hasMoreItems: false, loadMoreLoader: true});
      this.getBulkRechargeDetail()
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    }
  };

  getBulkRechargeDetail = async () => {
    const { rowsCount, pageCount, customerDetails, searchValue } = this.state;
    const payload = {
      accountDetails: {
        subscriberId: "",
        requestType: "GetSubIDList",
        "noOfRows": pageCount === 0 ? parseInt(rowsCount) : parseInt(pageCount*rowsCount+rowsCount),
        "page": pageCount,
        "viewRequestType": "BULKRECHARGE"
      }
    };
    try {
      // const response = await commonService.getBulkRechargeList(payload);
      const response = await commonService.subscriberListApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        let updateData = [...customerDetails];
        if(data.subscriberList.length){
          let customerDetails = data.subscriberList && data.subscriberList.map(obj => {
            return {
              // "chainId": data.chainId,
              "subscriberId": obj.subscriberId,
              "name": obj.name,
              "status": (
                <span
                  className={obj.accountStatus === "Temp Suspension" ? "status-temp-suspension" : `status-${obj.accountStatus.toLowerCase()}`}
                >
              { obj.accountStatus}
              </span>
              ),
              "balance": <ContentLoader rows={1} cellData={true} />,
              "mbr": <ContentLoader rows={1} cellData={true} />,
              "dueDate": <ContentLoader rows={1} cellData={true} />,
              "isChecked": false,
              "isDisabled": true
            }
          });
          updateData = updateData.concat(customerDetails);
          const promise = this.searchResolved(updateData, searchValue);
          promise.then((connectionsData) => {
            this.setState({
              customerDetails: connectionsData.data,
              noDataFound: connectionsData.noDataFound,
              isLoading: false,
              hasMoreItems: data.hasMoreItems,
              loadMoreLoader: false
            });
          });
          // this.setState({ isLoading: false, customerDetails: updateData, hasMoreItems: data.hasMoreItems, loadMoreLoader: false });
        } else this.setState({ isLoading: false, hasMoreItems: data.hasMoreItems, loadMoreLoader: false }); //customerDetails: [],
      } else {
        this.setState({ error: responseData.message, isLoading: false, loadMoreLoader: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false, loadMoreLoader: false })
    }
  };

  selectAllSubId = () => {
    const { customerDetails, isCheckedAll, showSubmitBtn } = this.state;
    const newDataList = [ ...customerDetails ];
    const newList = newDataList.map(obj => {
      const newObj = { ...obj };
      if(!obj.isDisabled) {
        newObj.isChecked = document.getElementById('checkUncheckAll').checked;
      } else newObj.isChecked = false;
      return newObj;
    });

    if (showSubmitBtn) {
      this.setState({
        customerDetails: newList,
        isCheckedAll: !isCheckedAll,
        showSubmitBtn: false
      });
    } else this.setState({
      customerDetails: newList,
      isCheckedAll: !isCheckedAll,
    });
  };

  checkboxHandler = (e) => {
    const { customerDetails } = this.state;
    const checkUncheckElement = document.getElementById('checkUncheckAll');
    if (checkUncheckElement) {
      this.setState({ isCheckedAll: false });
    }
    const updatedDataObj = getCheckedListNCountObj(customerDetails, e.target.value);
    if (updatedDataObj.checkedCount > 0) {
      this.setState({ showSubmitBtn: true })
    } else this.setState({ showSubmitBtn: false });
    this.setState({ customerDetails: updatedDataObj.updatedDataList });
  };

  handleRechargeBtn = (key) => {
    const { isSubmitted, customerDetails } = this.state;
    if (key === "isSubmitted") {
      let updateData = [...customerDetails];
      const rechargeDetailsArray = updateData.filter(currentObj => currentObj.isChecked && currentObj.isVisible);
      const rechargeTotalAmount = getTotalMBR(rechargeDetailsArray);
      this.setState({
        selectedCustomerDetails: rechargeDetailsArray,
        isSubmitted: !isSubmitted,
        totalAmount: rechargeTotalAmount
      });
    } else if (key === "back") {
      this.setState({ customerDetails, isSubmitted: !isSubmitted, error: "" });
    } else {
      //isRecharge
      if(localStorage.key("reChKey") || localStorage.key("blkRcTxnId")) {
        localStorage.removeItem("reChKey");//isRecharge
        localStorage.removeItem("blkRcTxnId");//bulkRechargeTransactionId
      }
      this.getBulkRecharge()
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    }
  };

  getBulkRecharge = async () => {
    this.setState({isLoading: true});
    const { selectedCustomerDetails, totalAmount } = this.state;
    let count = null;
    const rechargePacks = selectedCustomerDetails.map(obj=>{
      if(obj.mbr === "0"){
        count++;
      }
      return{
        "amount": obj.mbr,
        "subscriberId": obj.subscriberId
      }
    });
    if(!count){
      const payload = {
        "rechargePacks": rechargePacks,
        "totalAmount": totalAmount
      };
      try {
        const response = await commonService.getBulkRechargeStatus(payload);
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
          // this.setState({isLoading: false});
          const bulkRechargeData = responseData.data;
          const isRecharge = btoa(bulkRechargeData.isRecharge);
          const transactionId = btoa(bulkRechargeData.transactionId);
          localStorage.setItem("reChKey", `${isRecharge}`);
          localStorage.setItem("blkRcTxnId", `${transactionId}`);
          const bulkRechargeTextAreaElem = this.bulkRechargeTextArea.current;
          bulkRechargeTextAreaElem.value = bulkRechargeData.msg;
          const bulkRechargeInputElem = this.bulkRechargeInput.current;
          bulkRechargeInputElem.value = bulkRechargeData.txtPayCategory;
          const getThisForm = this.getThisForm.current;
          getThisForm.action = bulkRechargeData.billDeskUrl;
          getThisForm.submit();
        } else {
          this.setState({ error: responseData.message, isLoading: false })
        }
      } catch {
        this.setState({ error: GlobalConfig.serverError, isLoading: false })
      }
    } else this.setState({ error: "Recharge amount should not be 0", isLoading: false })

  };

  updateTotalAmount = (amount) => {
    this.setState({ totalAmount: amount })
  };

  onChangeHandler = (searchValue) => {
    const { customerDetails, isSubmitted } = this.state;
    if(!isSubmitted){
      this.setState({
        searchValue
      });
      const promise = this.searchResolved(customerDetails, searchValue);
      promise.then((data) => {
        this.setState({ customerDetails: data.data, noDataFound: data.noDataFound });
      })
    }
  };

  searchResolved = (data, value) => {
    return new Promise((resolve) => {
      get(this.callApiOnDebounce, 'cancel', null) && this.callApiOnDebounce.cancel();
      this.callApiOnDebounce = debounce(() => {
        const searchResult = getSearchResult(data, "subscriberId", value);
        resolve(searchResult);
      }, 500);
      this.callApiOnDebounce();
    });
  };

  //sorting Function
  sortHandler = (key) => {
    const { customerDetails, sortDirection } = this.state;
    let sortedData = [];
    if(key === "dueDate") {
      customerDetails.map(obj=> {
        let dueDateValue = obj.dueDate.props ? obj.dueDate.props.children : obj.dueDate;
        obj.overDueDate = !!obj.dueDate.props;
        obj.dueDate = dueDateValue ? getTimeStamp(dueDateValue) : '';
        return obj;
      });
      sortedData = sortBy(customerDetails, key, sortDirection, "number").map(sortedObj => {
        if(sortedObj.dueDate){
          sortedObj.dueDate = sortedObj.overDueDate ?
            (<span className="dueDateColor">{dateFormatter(sortedObj.dueDate)}</span>) :
            (dateFormatter(sortedObj.dueDate));
        } else sortedObj.dueDate = <ContentLoader rows={1} cellData={true} />;
        delete sortedObj.overDueDate;
        return sortedObj;
      });
    } else if(key === "status") {
      sortedData = sortByObjValue(customerDetails, key, sortDirection);
    } else if(key === "name"){
      sortedData = sortBy(customerDetails, key, sortDirection);
    } else {
      sortedData = sortBy(customerDetails, key, sortDirection, "number");
    }
    this.setState({
      customerDetails: sortedData,
      sortDirection: sortDirection === 'asc' ? 'dsc' : 'asc'
    })
  };
  //sorting ends

  webSocketResponse = (msg) => {
    const { customerDetails } = this.state;
    if (msg.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
      // console.log("websocket response data======", msg.data);
      const responseData = msg.data;
      const updateData = [...customerDetails];
      const customerDetailsFiltered = updateData.map(obj => {
        if (obj.subscriberId === responseData.subscriberId) {
          // console.log("websocket response data======", {obj, responseData});
          obj =
            {
              ...obj,
              // "chainId": obj.chainId,
              "subscriberId": obj.subscriberId,
              "balance": responseData.accountBalance || "0",
              "mbr": responseData.monthlyCharge || "0",
              "dueDate": responseData.overDueDate ?
                (<span className="dueDateColor">{dateFormatter(responseData.nextRechargeDate)}</span> || "-") :
                (dateFormatter(responseData.nextRechargeDate) || "-"),
              "status": obj.status,
              "isChecked": obj.isChecked,
              "isDisabled": !(responseData.accountBalance
                || responseData.monthlyCharge
                || responseData.nextRechargeDate) || false
            };
        }
        return obj;
      });
      this.setState({ customerDetails: customerDetailsFiltered, isDisabledCheckedAll: false })
    } else{
      console.log("============== Msg status is not matched ===========", msg)
      this.callBackForWebSocketData()
          .catch(() => {
            console.error(MESSAGES.DID_MOUNT_ERROR);
          });
    }
  };

  callBackForWebSocketData = async () => {
    const { customerDetails } = this.state;
    let subIdArray = [];
    customerDetails.forEach((obj) => {
      if(obj.isDisabled) {
        subIdArray.push(obj.subscriberId)
      }
    });
    if(subIdArray.length){
      // console.log("call back subIdArray============", subIdArray);
      this.callBackOnConnect();
    }
  };

  callBackOnConnect = () => {
    this.setState({customerDetails : [], isLoading: true}, () =>
    {
      this.getBulkRechargeDetail()
          .catch(() => {
            console.error(MESSAGES.DID_MOUNT_ERROR);
          });
    });
  };

  render() {
    const {
      onChangeHandler,
      selectAllSubId,
      checkboxHandler,
      handleRechargeBtn,
      updateTotalAmount,
      sortHandler
    } = this;

    const {
      isLoading,
      loadMoreLoader,
      isDisabledCheckedAll,
      isCheckedAll,
      isSubmitted,
      showSubmitBtn,
      customerDetails,
      selectedCustomerDetails,
      isTotalEdit,
      totalAmount,
      noDataFound,
      error
    } = this.state;
    const webSocketUrl = `${GlobalConfig.API_ROOT}/stomp`;
    return (
      <div className="b2b-bulk-recharge">
        <SockJsClient
          url={webSocketUrl}
          topics={[ `/topic/bulkRechargeView` ]}
          onMessage={this.webSocketResponse}
          onConnectFailure={this.callBackOnConnect}
          onDisconnect={this.callBackOnConnect}
          // onDisconnect={() => console.log("onDisconnect================")}
          onConnect={this.callBackOnConnect}
          // debug={true}
        />
        {
          isLoading && <Loader/>
        }
        <form method="post" ref={this.getThisForm} className="d-none">
          <textarea ref={this.bulkRechargeTextArea} name="msg" />
          <input type="text" ref={this.bulkRechargeInput} name="txtPayCategory"/>
        </form>

        <div className="content-wrapper pb60">
          {
            isSubmitted ?
              <div className="prev-page-link pb20" onClick={() => handleRechargeBtn("back")}>
                <i className="back-link">
                  <svg width="25" height="16">
                    <path fill="currentColor" fillRule="evenodd"
                          d="M8.336.599a.923.923 0 0 1 1.211 0 .703.703 0 0 1 0 1.067L3.406 7.131h20.265c.473 0 .86.334.86.755 0 .42-.387.765-.86.765H3.406l6.141 5.456a.714.714 0 0 1 0 1.077.923.923 0 0 1-1.211 0L.741 8.425a.703.703 0 0 1 0-1.067L8.336.599z"/>
                  </svg>
                </i>
                Back
              </div>
              :
              <Fragment />
          }

          <div className="d-flex justify-content-between">
            <h2 className="heading">
              Bulk Recharge
            </h2>
            <Search
              onChangeSearchHandler={onChangeHandler}
              placeHolder={PLACEHOLDER.DASHBOARD}
              disabled={isSubmitted}
            />
          </div>
          {
            !noDataFound && customerDetails.length && error ? (
              <div className="mt20">
                <Error error={error}/>
              </div>
            ) : <Fragment />
          }

          {
            !noDataFound && customerDetails && customerDetails.length ? (
              <SubscriberDetails
                isDisabledCheckedAll={isDisabledCheckedAll}
                isCheckedAll={isCheckedAll}
                isSubmitted={isSubmitted}
                showSubmitBtn={showSubmitBtn}
                customerDetails={isSubmitted ? selectedCustomerDetails : customerDetails}
                isTotalEdit={isTotalEdit}
                totalAmount={totalAmount}
                selectAllSubId={selectAllSubId}
                checkboxHandler={checkboxHandler}
                handleRechargeBtn={handleRechargeBtn}
                updateTotalAmount={updateTotalAmount}
                sortHandler={sortHandler}
              />
            ) : (
              !isLoading && <div className="no-data-found mt30">No data found</div>
            )
          }
          {
            loadMoreLoader && <div className="d-flex justify-content-center mt20"> <ReactLoading type={'bars'} color={'#67757d'} /> </div>
          }
        </div>
      </div>
    )
  }
}

export default BulkRecharge;
