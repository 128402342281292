import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {closeModal, openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import Loader from "../../../Loader";
// import {PATHS} from "../../../../constants";
import Error from "../../../Common/Error";
import {isArray} from 'lodash';
import {MODALS} from "../../../../storeManager/modal/constants";

class MultipleChainId extends Component {
    state = {
        error: '',
        isLoading: false,
        chainIdList: []
    };

    componentDidMount() {
        const { modalParameters: {chainIdList} } = this.props;
        this.setState({chainIdList})
    }


    inputChangeHandler = (event) => {
        const {name, value, id} = event.target;
        const userName = btoa(id);
        const userChainId = btoa(value);
        localStorage.setItem("key", `${userName}`);
        localStorage.setItem("chKeyId", `${userChainId}`);
        this.setState({
            [name]: value,
          userName: id,
          error: ''
        })
    };

    submitForm = async (e) => {
        e.preventDefault();
        const {chainId,userName} = this.state;
        const {openModal, modalParameters: {mobileNumber}} = this.props;
        if(chainId){
          openModal(MODALS.LOGIN_OTP, { chainId, mobileNumber, userName });
        } else {
            this.setState({error: "Please select a Chain Id"})
        }
    };

    render() {
        const { error, isLoading, chainIdList} = this.state;
        const {
            inputChangeHandler,
            submitForm,
        } = this;

        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }

                <div className="b2b-modal-header center mb20">
                  <div className="gradient-text">
                      Please select Chain ID
                  </div>
                </div>

                <div className="prl20">
                    <form onSubmit={(e) => submitForm(e)}>
                      <div className="chainId-header">
                        <span>Chain ID</span>
                        <span>Status</span>
                        <span>Name</span>
                      </div>
                      <div className="chain-list-container">
                        {
                          isArray(chainIdList) ? chainIdList.map((list, index) => {
                            return (
                                <div key={`chainId-list-${index}`} className="chainId-list">
                                  <label
                                      className="radio-element"
                                      htmlFor={list.name}
                                  >
                                    <input
                                        type="radio"
                                        name="chainId"
                                        id={list.name}
                                        value={list.subscriberId}
                                        onChange={(e)=>inputChangeHandler(e)}/>
                                    <span className="select-radio"/>
                                    <span className="radio-text"> {list.subscriberId} </span>
                                  </label>
                                  <span>{list.accountStatus}</span>
                                  <span>{list.name}</span>
                                </div>
                            )
                          }) : null
                        }
                      </div>
                      <Error error={error} />
                        <button
                            type="button"
                            className="button fill w-140 big mtb10"
                            onClick={(e) => submitForm(e)}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(MultipleChainId));
