import React, {Component} from 'react';
import './../../assets/stylesheet/component/_input-element.scss';
import Search from "../Common/Search";
import {API_REQUEST_KEYS, MESSAGES, PLACEHOLDER, infiniteScroll} from "../../constants";
import Loader from "../Loader";
import Connection from "./Connection";
import Modal from "../Common/Modal";
import {connect} from "react-redux";
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../storeManager/modal/actions";
import {isArray, debounce, get} from "lodash";
import ReactLoading from 'react-loading';
import {getSearchResult} from "../../helper";

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModalHandler, dispatch),
});

class Dashboard extends Component {
  recomendationParamInput = React.createRef();
  getThisForm = React.createRef();
  callApiOnDebounce = null;

  state = {
    chainId: '', // not subscriberId
    openAccordion: "serialID",
    connections: [],
    isLoading: true,
    searchValue: '',
    rowsCount: infiniteScroll.defaultRowCount,
    pageCount: infiniteScroll.pageCount,
    hasMoreItems: false,
    loadMoreLoader: false,
    noDataFound: false
  };

  componentDidMount() {
    const { location: { state } } = this.props;
    this.getConnections((state && state.chainId) || "")
      .catch(() => {
        console.error(MESSAGES.DID_MOUNT_ERROR);
      });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { hasMoreItems, pageCount, noDataFound } = this.state;
    const footerHeight = document.querySelector('footer').offsetHeight;
    if (((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - footerHeight))
      && hasMoreItems && !noDataFound) {
      this.setState({ pageCount: pageCount + 1, loadMoreLoader: true, hasMoreItems: false });
      this.getConnections("")
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    }
  };

  onChangeSearchHandler = (searchValue) => {
    const { connections } = this.state;
    this.setState({
      searchValue
    });
    const promise = this.searchResolved(connections, searchValue);
    promise.then((data) => {
      this.setState({ connections: data.data, noDataFound: data.noDataFound });
    })
  };

  searchResolved = (data, value) => {
    return new Promise((resolve) => {
      get(this.callApiOnDebounce, 'cancel', null) && this.callApiOnDebounce.cancel();
      this.callApiOnDebounce = debounce(() => {
        const searchResult = getSearchResult(data, "subscriberId", value);
        resolve(searchResult);
      }, 500);
      this.callApiOnDebounce();
    });
  };

  getConnections = async (chainId) => {
    const { rowsCount, pageCount, connections, searchValue } = this.state;
    const payload = {
      "accountDetails": {
        "noOfRows": rowsCount,
        "page": pageCount,
        "subscriberId": chainId,
        "requestType": API_REQUEST_KEYS.requestType.getSubIdList,
        "viewRequestType": "DASHBOARD"
      }
    };
    try {
      const response = await commonService.subscriberListApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        let updateData = [ ...connections ];
        if (Array.isArray(data.subscriberList) && data.subscriberList.length > 0) {
          updateData = updateData.concat(data.subscriberList);
          updateData[ 0 ].isShow = true;
          const promise = this.searchResolved(updateData, searchValue);
          promise.then((connectionsData) => {
            this.setState({
              connections: connectionsData.data,
              noDataFound: connectionsData.noDataFound,
              isLoading: false,
              hasMoreItems: data.hasMoreItems,
              loadMoreLoader: false
            });
          })
        } else {
          if(data.hasMoreItems) {
            this.setState({ pageCount: pageCount + 1, loadMoreLoader: true, hasMoreItems: data.hasMoreItems });
            this.getConnections("")
                .catch(() => {
                  console.error(MESSAGES.DID_MOUNT_ERROR);
                });
          } else {
            this.setState({hasMoreItems: data.hasMoreItems, isLoading: false, loadMoreLoader: false});
          }
        }
      } else {
        this.setState({ error: responseData.message, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
    }
  };

  toggleAccordion = (value) => {
    const { connections } = this.state;
    let connectionsArray = [ ...connections ];
    connectionsArray && connectionsArray.forEach((val, index) => {
      if (index === value) {
        val.isShow = !val.isShow;
      } else {
        val.isShow = false;
      }
    });
    this.setState({
      connections: connectionsArray
    })
  };

  refreshConnections = (subId) => () => {
    const { connections, pageCount, rowsCount } = this.state;
    const connectionsData = [ ...connections ];
    const updateData = connectionsData.map(obj => {
      if (obj.subscriberId === subId) {
        obj.hardReset = false;
      }
      return obj;
    });
    this.setState({ connections: updateData });
    const payload = {
      "subscriberId": subId,
      "noOfRows": pageCount === 0 ? parseInt(rowsCount) : parseInt(pageCount*rowsCount+rowsCount)
    };
    try {
      const response = commonService.hardResetApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
      }
      /*else {
        this.setState({error: responseData.message})
      }*/
    } catch {
      // this.setState({error: GlobalConfig.serverError})
    }
  };

  redirectModifyPack = (subId, name) => async () => {
    this.setState({ isLoading: true });
    const payload = {
      "subscriberId": subId,
      "subscriberName": name
    };
    try {
      const response = await commonService.redirectModifyPackApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({ isLoading: false });
        const modifyPackData = responseData.data;
        const recommendationParamElem = this.recomendationParamInput.current;
        recommendationParamElem.value = modifyPackData.recomendationParam;
        const getThisForm = this.getThisForm.current;
        getThisForm.action = modifyPackData.redirectUrl;
        getThisForm.submit();
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };


  rechargeRedirect = (subId) => async () => {
    this.setState({ isLoading: true });

    try {
      const response = await commonService.redirectRechargeApi(subId);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({ isLoading: false });
        const rechargeUrl = responseData.data;
        window.open(rechargeUrl, '_blank')
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };

  render() {
    const { openModal } = this.props;
    const { connections, isLoading, loadMoreLoader, noDataFound, pageCount, rowsCount } = this.state;
    const { toggleAccordion, refreshConnections, redirectModifyPack, onChangeSearchHandler, rechargeRedirect } = this;
    return (
      <div className="b2b-dashboard">
        {
          isLoading && <Loader/>
        }

        <form method="post" ref={this.getThisForm} className="d-none" target='_blank'>
          <textarea ref={this.recomendationParamInput} name="recomendationParam"/>
        </form>

        <div className="content-wrapper">
          <div className="d-flex justify-content-between mb20">
            <h2 className="heading">
              Your Connections
            </h2>
            <Search
              onChangeSearchHandler={onChangeSearchHandler}
              placeHolder={PLACEHOLDER.DASHBOARD}
            />
          </div>
          <div className="connection-list">
            {
              noDataFound ? (
                <div className="no-data-found">No Data Found</div>
              ) : (
                isArray(connections) && connections.length ? connections.map((data, index) => {
                    return data.isVisible ? (
                      <Connection
                        key={index}
                        serial={index}
                        data={data}
                        // locationImage={data.imageUrl || placeHolderIcon}
                        showHideAccordion={toggleAccordion}
                        refreshConnections={refreshConnections}
                        modifyPackRedirect={redirectModifyPack}
                        rechargeRedirect={rechargeRedirect}
                        openModal={openModal}
                        connectionLength={pageCount === 0 ? parseInt(rowsCount) : parseInt(pageCount*rowsCount+rowsCount)}
                      />
                    ) : null
                  }
                ) : (
                  <div className="no-data-found">No Data Found</div>
                )
              )
            }
            {/* Balance Details Modal */}
            <Modal/>
            {/* End */}
            {
              loadMoreLoader &&
              <div className="d-flex justify-content-center"><ReactLoading type={'bars'} color={'#67757d'}/></div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Dashboard);
