import React, {Fragment, Component} from 'react';
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import Loader from "../../Loader";
import {MESSAGES} from "../../../constants";
import Error from "../../Common/Error";
import TableComponent from "../../Common/TableComponent";
import {MODALS} from "../../../storeManager/modal/constants";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
import {connect} from "react-redux";

class ActivityDetailModal extends Component {
    state = {
        isLoading: true,
        activityModalData: {},
        error: ''
    };

    componentDidMount() {
        this.getActivitiesDetails()
            .catch(() => {
                console.error(MESSAGES.DID_MOUNT_ERROR);
            });
    }

    getActivitiesDetails = async () => {
        const {modalParameters: {transactionId, activityType}} = this.props;
        const payload = {
            transactionId,
            activityType
        };
        try {
            const response = await commonService.activitiesDetailsApi(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
              let activityDetailData = responseData.data;
              const filterData= activityDetailData.map(columnData => {
                if(columnData.amount) {
                  return {
                    subscriberId: columnData.subscriberId,
                    transactionId: columnData.transactionId,
                    status: <span
                      className="text-btn"
                      onClick={()=>this.openConfirmationModal(columnData.transactionId)}
                    >
                          Click Here
                          </span>,
                    amount: columnData.amount,
                    packName: columnData.packName,
                  }
                } else {
                  return {
                    subscriberId: columnData.subscriberId,
                    transactionId: columnData.transactionId,
                    status: <span
                      className="text-btn"
                      onClick={()=>this.openConfirmationModal(columnData.transactionId)}
                    >
                          Click Here
                          </span>,
                    packName: columnData.packName,
                  }
                }

              });
                this.setState({activityModalData: filterData, isLoading: false});
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };


  openConfirmationModal = async (id) => {
      this.setState({isLoading: true});
    const { openModal } = this.props;
    const payload = {
      transactionId: id,
    };
    try {
      const response = await commonService.activitiesDetailConfirmationApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({isLoading: false});
        openModal(MODALS.CONFIRMATION_POPUP, {message: responseData.message});
      } else {
        this.setState({error: responseData.message, isLoading: false})
      }
    } catch {
      this.setState({error: GlobalConfig.serverError, isLoading: false})
    }

  };

    render() {
        const {activityModalData, isLoading, error} = this.state;
      const {modalParameters: {transactionId}} = this.props;
        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <div className="b2b-modal-header">
                    Primary Transaction ID
                </div>
                <div className="sub-heading pb10">{transactionId ? transactionId : 'NA'}</div>
                {
                    !error && activityModalData.length ? (
                        <div className="b2b-modal-info">
                          <TableComponent
                            tableData={activityModalData}
                            // tableClass={"recent-activities-table"}
                          />
                        </div>
                    ) : <Error error={error}/>
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: bindActionCreators(openModalHandler, dispatch),
  }
};

export default connect(null, mapDispatchToProps)(ActivityDetailModal);