import {ACTION} from './constants';

const initialState = {

};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.RESET_STATE:
      return {...initialState};
    default:
      return state;
  }
}