import React from 'react';
import './../../../assets/stylesheet/component/_input-element.scss';
import {getLocalStorage} from '../../../services/commonUtils';
import AdminUser from './AdminUser';
import SalesUser from './SalesUser';
import {isAdminAuthenticated} from '../../../helper';
import {PATHS} from "../../../constants";
import {withRouter} from 'react-router-dom';

const  AdminDashboard = (props) => {

  if(isAdminAuthenticated()){
    const userType = getLocalStorage('admin');
    if(userType && userType.role === 'admin' ){
    return <AdminUser />
  } 
  return <SalesUser />
} else {
  const {history} = props;
  history.push(PATHS.ADMIN)
  return null
}
}

export default withRouter(AdminDashboard);
