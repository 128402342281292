import React, {Component} from 'react';
import ModifyTable from './ModifyTable';
import {infiniteScroll, MESSAGES, PATHS, PLACEHOLDER} from "../../constants";
import Search from "../Common/Search";
import './bulk-modify.scss';
import Loader from "../Loader";
import {getCheckedListNCountObj, getSearchResult} from "../../helper";
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import Error from "../Common/Error";
import ReactLoading from "react-loading";
import {debounce, get} from "lodash";
// import customerDetails from "../../mocks/bulkModify"

class BulkModify extends Component {

  state = {
    isLoading: true,
    loadMoreLoader: false,
    isCheckedAll: false,
    isProceed: false,
    isSubmit: false,
    showProceedBtn: false,
    modifyPacks: [],
    modifyPacksError: '',
    customerDetails: [],
    selectedCustomerDetails: [],
    isTotalEdit: false,
    searchValue: "",
    selectedPack: "",
    error: "",
    rowsCount: infiniteScroll.bulkModifyRowCount,
    pageCount: infiniteScroll.pageCount,
    hasMoreItems: false,
    noDataFound: false
  };

  componentDidMount() {
    this.getServicePack()
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });

    this.getConnections()
    .catch(() => {
      console.error(MESSAGES.DID_MOUNT_ERROR);
    });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { hasMoreItems, pageCount, noDataFound } = this.state;
    const footerHeight = document.querySelector('footer').offsetHeight;
    if (((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - footerHeight))
      && hasMoreItems && !noDataFound) {
      this.setState({pageCount: pageCount + 1, hasMoreItems: false, loadMoreLoader: true});
      this.getConnections()
        .catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
    }
  };

  onChangeHandler = (searchValue) => {
    const { customerDetails, isProceed } = this.state;
    if(!isProceed){
      this.setState({
        searchValue
      });
      const promise = this.searchResolved(customerDetails, searchValue);
      promise.then((data) => {
        this.setState({ customerDetails: data.data, noDataFound: data.noDataFound });
      })
    }
  };

  searchResolved = (data, value) => {
    return new Promise((resolve) => {
      get(this.callApiOnDebounce, 'cancel', null) && this.callApiOnDebounce.cancel();
      this.callApiOnDebounce = debounce(() => {
        const searchResult = getSearchResult(data, "subscriberId", value);
        resolve(searchResult);
      }, 500);
      this.callApiOnDebounce();
    });
  };

  selectAllSubId = () => {
    const { customerDetails, isCheckedAll, showProceedBtn } = this.state;
    const newDataList = [ ...customerDetails ];
    const newList = newDataList.map(obj => {
      const newObj = { ...obj };
      newObj.isChecked = document.getElementById('checkUncheckAll').checked;
      return newObj;
    });

    if (showProceedBtn) {
      this.setState({
        customerDetails: newList,
        isCheckedAll: !isCheckedAll,
        showProceedBtn: false
      });
    } else this.setState({
      customerDetails: newList,
      isCheckedAll: !isCheckedAll,
    });
  };

  checkboxHandler = (e) => {
    const { customerDetails } = this.state;
    const checkUncheckElement = document.getElementById('checkUncheckAll');
    if (checkUncheckElement) {
      this.setState({ isCheckedAll: false });
    }
    const updatedDataObj = getCheckedListNCountObj(customerDetails, e.target.value);
    if (updatedDataObj.checkedCount > 0) {
      this.setState({ showProceedBtn: true })
    } else this.setState({ showProceedBtn: false });
    this.setState({ customerDetails: updatedDataObj.updatedDataList });
  };

  handleProceedBtn = (key) => {
    const { isProceed, customerDetails, selectedPack, selectedCustomerDetails, searchValue } = this.state;
    if (key === "isProceed") {
      if(!selectedPack) {
        this.setState({ error: "Please select a valid pack" });
      } else {
        const modifyDetailsArray = customerDetails.filter(currentObj => {
          currentObj.isChecked && (currentObj.packName = selectedPack);
          return currentObj.isChecked;
        });
        this.setState({ selectedCustomerDetails: modifyDetailsArray, isProceed: !isProceed });
      }
    } else if (key === "isSubmit") {
      this.setState({ isLoading: true });
      let bulkModifyPackPayload = [];
      const promise = this.searchResolved(selectedCustomerDetails, searchValue);
      promise.then((data) => {
        bulkModifyPackPayload = data.data.filter(obj => {
          if(obj.isVisible){
            return obj;
          } return null
        }).map(filteredObj => {
          return {
            "subscriberId": filteredObj.subscriberId,
            "addInputList":[
              {
                "addInputName": filteredObj.packName
              }
            ],
            "dropInputList":[]
          };
        });
        this.submitSelectedDetails(bulkModifyPackPayload).catch(() => {
          console.error(MESSAGES.DID_MOUNT_ERROR);
        });
      });
    } else if (key === "back") {
      this.setState({customerDetails, isProceed: !isProceed});
    }
  };


  getServicePack = async () => {
    try {
      const response = await commonService.getServicePackRequestApi();
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        this.setState({modifyPacks: data}); //removed isLoading: false
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };

  getConnections = async () => {
    const { rowsCount, pageCount, customerDetails, searchValue } = this.state;
    const payload = {
      "accountDetails":{
        "noOfRows": pageCount === 0 ? parseInt(rowsCount) : parseInt(pageCount*rowsCount+rowsCount),
        "page": pageCount,
        "subscriberId": "",
      }
    };
    try {
      const response = await commonService.getSubscriberListWithChainId(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let data = responseData.data;
        let updateData = [...customerDetails];
        if(data.subscriberList.length) {
          let customerDetails = data.subscriberList && data.subscriberList.map(subId => {
            return {
              "chainId": data.chainId,
              "subscriberId": subId,
              "isChecked": false
            }
          });
          updateData = updateData.concat(customerDetails);
          const promise = this.searchResolved(updateData, searchValue);
          promise.then((connectionsData) => {
            this.setState({
              customerDetails: connectionsData.data,
              noDataFound: connectionsData.noDataFound,
              isLoading: false,
              hasMoreItems: data.hasMoreItems,
              loadMoreLoader: false
            });
          });

        } else {
          this.setState({isLoading: false, hasMoreItems: data.hasMoreItems, loadMoreLoader: false}); //customerDetails: [],
        }

      } else {
        this.setState({ modifyPacksError: responseData.message, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
      }
    } catch {
      this.setState({ modifyPacksError: GlobalConfig.serverError, isLoading: false, hasMoreItems: false, loadMoreLoader: false })
    }
  };

  selectPackValue = (e) => {
    this.setState({ selectedPack: "", error: "" });
    const {modifyPacks} = this.state;
    const key = e.target.value;
    modifyPacks && modifyPacks.forEach(obj => {
      if(key === obj.displayName){
        this.setState({ selectedPack: obj.packName, error: "" })
      }
    });
  };

  submitSelectedDetails = async (bulkModifyPackPayload) => {
    const payload = {
      bulkModifyPackPayload
    };
    try {
      const response = await commonService.bulkModifySubmitApi(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({isLoading: false});
        const { history } = this.props;
        history.push({
          pathname: PATHS.TRANSACTION_STATUS,
          state: {
            message: responseData.message,
            status: GlobalConfig.MESSAGES_TYPES.SUCCESS,
            masterTransactionId: responseData.data
          }
        });
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };


  render() {
    const { onChangeHandler, selectAllSubId, checkboxHandler, handleProceedBtn, selectPackValue } = this;
    const {
      isCheckedAll,
      isProceed,
      isSubmit,
      isLoading,
      loadMoreLoader,
      showProceedBtn,
      isTotalEdit,
      modifyPacks,
      customerDetails,
      selectedCustomerDetails,
      error,
      noDataFound
    } = this.state;

    return (
        <div className="b2b-bulk-modify">
          {
            isLoading && <Loader/>
          }

          <div className="content-wrapper pb60">
            {
              isProceed ?
                  <div className="prev-page-link pb20" onClick={() => handleProceedBtn("back")}>
                    <i className="back-link">
                      <svg width="25" height="16">
                        <path fill="currentColor" fillRule="evenodd"
                              d="M8.336.599a.923.923 0 0 1 1.211 0 .703.703 0 0 1 0 1.067L3.406 7.131h20.265c.473 0 .86.334.86.755 0 .42-.387.765-.86.765H3.406l6.141 5.456a.714.714 0 0 1 0 1.077.923.923 0 0 1-1.211 0L.741 8.425a.703.703 0 0 1 0-1.067L8.336.599z"/>
                      </svg>
                    </i>
                    Back
                  </div>
                  :
                  null
            }

            <div className="d-flex justify-content-between">
              <h2 className="heading pr60">
                Bulk Modify
              </h2>
              <div className="form-element">
                <input
                  list="modifyPacksValue"
                  name="modifyPacksValue"
                  className="select-pack ml60 mr20 shadow white-bg input-element"
                  placeholder="-- Select Pack --"
                  onChange={(e)=>selectPackValue(e)}
                  disabled={isProceed}
                />
                <datalist
                  id="modifyPacksValue"
                  // className="select-pack p10 ml60 mr20 shadow white-bg"
                  // defaultValue
                  // onChange={(e)=>selectPackValue(e)}
                  // disabled={isProceed}
                >
                  {/*<option value="-- Select Pack --" defaultValue hidden />*/}
                  {
                    modifyPacks && modifyPacks.map((obj, index) => (
                      <option key={index} value={obj.displayName} />
                      )
                    )
                  }
                </datalist>
                <Error error={error}/>
              </div>
              <Search
                onChangeSearchHandler={onChangeHandler}
                placeHolder={PLACEHOLDER.DASHBOARD}
                disabled={isProceed}
              />
            </div>
            {
              !noDataFound && customerDetails && customerDetails.length ? (
                <ModifyTable
                  customerDetails={isProceed ? selectedCustomerDetails : customerDetails}
                  selectAllSubId={selectAllSubId}
                  checkboxHandler={checkboxHandler}
                  handleProceedBtn={handleProceedBtn}
                  isCheckedAll={isCheckedAll}
                  isProceed={isProceed}
                  isSubmit={isSubmit}
                  showProceedBtn={showProceedBtn}
                  isTotalEdit={isTotalEdit}
                />
              ) : (
                !isLoading && <div className="no-data-found mt30">No data found</div>
              )
            }
            {
              loadMoreLoader && <div className="d-flex justify-content-center mt20"> <ReactLoading type={'bars'} color={'#67757d'} /> </div>
            }
          </div>
        </div>
    )
  }
}

export default BulkModify;
