export const PATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  HOME: '/dashboard',
  DASHBOARD: '/dashboard',
  ESCALATION_MATRIX: '/escalation-matrix',
  BULK_RECHARGE: '/bulk-recharge',
  BULK_MODIFY: '/bulk-modify',
  ACTIVITIES: '/activities',
  TEMPORARILY_SUSPEND: '/temporarily-suspend',
  SERVICE_REQUEST: '/service-request',
  TRANSACTION_HISTORY: '/transaction-history',
  SERVER_ERROR: '/server-error',
  SOMETHING_WENT_WRONG: '/something-went-wrong',
  TRANSACTION_STATUS: '/transaction-status',
  ACTIVITY_DETAIL: '/activities/detail',
  PROFILE: '/profile',
  RESET_PASSWORD: '/reset-password',
  ADMIN:'/admin',
  ADMIN_DASHBOARD: '/admin-dashboard',
  ADMIN_ADD_USER: '/add-user',
};

export const PLACEHOLDER = {
  DASHBOARD: "Search by Subscriber ID",
  BULK_RECHARGE: "Search by Chain ID or Subscriber ID",
};

export const TABLE_HEADER = {
  chainId: "Chain Id",
  subscriberId: "Subscriber Id",
  balance: "Balance",
  mbr: "MBR",
  dueDate: "Due Date",
  packName: "Pack Name",
  name: "Name",
  emailId: "Email ID",
  mobileNumber: "Mobile Number",
  contactNumber: "Mobile Number",
  date: "Date",
  tansactionType: "Transaction Type",
  description: "Description",
  TaxInvoice: "Tax Invoice",
  amount: "Amount",
  srOpenDate: "Created Date",
  srNumber: "Request #",
  srType: "Nature of Request",
  srArea: "Type",
  srDueDate: "Due Date",
  status: "Status",
  activityType: "Type",
  transactionId: "Transaction ID",
  escalationLevel: "Escalation Level"
};

export const MESSAGES = {
  SUSPEND_SUCCESS: "Your Account has been suspended successfully",
  TRANSACTION_SUCCESS: "Congrats! Your account has been recharged successfully",
  TRANSACTION_FAILURE: "Oops! Your transaction is failed.",
  SOMETHING_WENT_WRONG: "Oh no, something went wrong !",
  SERVER_ERROR: "Server error !",
  NON_EXIST_EMAIL: "Email ID not configured for this account. Please contact customer care for support",
  DID_MOUNT_ERROR: "API is not working",
  INVALID_CAPTCHA: "Invalid Captcha",
};

export const INPUT_ERROR = {
  REQUIRED: "This field is required",
  CHAIN_ID: "Chain Id is not valid",
  PASSWORD: "Password can't be less than 8 characters",
  PATTERN_PASSWORD: "Password should be alpha-numeric, special character, uppercase and lower case should be used.",
  CONFIRM_PASSWORD: "Please enter the Confirm password same as password",
  MOBILE_NUMBER: "Mobile Number is not valid",
  EMAIL: "Email id is not valid"
};

export const API_REQUEST_KEYS = {
  // source: "B2BPORTAL",
  flowName: {
    b2bPortal_OTP: "B2BPORTAL-OTP",
    getAccDetails: "GET_ACC_DETAILS"
  },
  requestType: {
    chainValidation: "ChainValidation",
    getSubIdList: "GetSubIDList",
    hr: "HR",
    sr: "SR"
  },
  actionType: {
    suspend: "Suspend",
    resume: "Resume",
  }
};

export const infiniteScroll = {
  pageCount: 0,
  defaultRowCount: 25, //25
  bulkModifyRowCount: 200, //200
  activityRowCount: 50 //50
};
