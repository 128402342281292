import React, { Component, Fragment } from 'react';
import './bulk-modify.scss';
import SelectSubscriber from "./SelectSubscriber";
// import TableComponent from "../Common/TableComponent";

class ModifyTable extends Component {
  render() {
    const {
      isCheckedAll,
      isProceed,
      customerDetails,
      showProceedBtn,
      selectAllSubId,
      handleProceedBtn,
      checkboxHandler,
    } = this.props;

    const columnWidth = isProceed ? "modify-pack-table" : "";

    return (
      <Fragment>
          <div className="d-flex align-items-center action-row p20">
              {
                  !isProceed ? (
                      <Fragment>
                          <label className="checkbox-element p10">
                              <input
                                  id="checkUncheckAll"
                                  type="checkbox"
                                  name="recharge"
                                  checked={isCheckedAll}
                                  onChange={() => selectAllSubId()}
                              />
                              <span className="checkmark"/>
                          </label>
                          {
                              (isCheckedAll || showProceedBtn) &&
                              <button
                                  type="button"
                                  className="button shadow white-bg w-140 ml15"
                                  onClick={() => handleProceedBtn('isProceed')}
                              >
                                  Proceed
                              </button>
                          }
                      </Fragment>
                  ) : (
                      <button
                          type="button"
                          className="button shadow white-bg w-140"
                          onClick={() => handleProceedBtn('isSubmit')}
                      >
                          Submit
                      </button>
                  )
              }
          </div>

          <SelectSubscriber
              tableData={customerDetails}
              checkboxHandler={checkboxHandler}
              isProceed={isProceed}
              tableClass={columnWidth}
          />
      </Fragment>
    );
  }
}

export default ModifyTable;
