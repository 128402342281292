import React, { Component } from 'react';
import './escalation-matrix.scss';
import TableComponent from "../Common/TableComponent";
// import ExMatrixData from '../../mocks/excalationMatrix'
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import Loader from "../Loader";
import {MESSAGES} from "../../constants";

class EscalationMatrix extends Component {
    state = {
      escalationMatrixData: [],
      isLoading: true
    };

    componentDidMount() {
      this.getEscalationMatrixDetail().catch(() => {
          console.log(MESSAGES.DID_MOUNT_ERROR)
      })
    }


  getEscalationMatrixDetail = async () => {
    try {
      const response = await commonService.getEscalationMatrixList();
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        this.setState({ isLoading: false, escalationMatrixData: responseData.data });
      } else {
        this.setState({ error: responseData.message, isLoading: false })
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isLoading: false })
    }
  };
    render() {
        const { escalationMatrixData, isLoading } = this.state;
        return (
            <div className="b2b-escalation-matrix">
              {
                isLoading && <Loader />
              }
                <div className="content-wrapper pb60">
                    <div className="d-flex pt10 pb30">
                        <h2 className="heading">
                            Escalation Matrix
                        </h2>
                    </div>
                    <TableComponent
                      tableData={escalationMatrixData}
                      tableClass={"escalation-table"}
                    />
                </div>
            </div>
        )
    }
}

export default EscalationMatrix;
