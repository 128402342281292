import React from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import {PATHS} from './constants/index';
import {isAuthenticated} from './helper';
import CommonLayout from '../src/components/Common/CommonLayout';
import Auth from "./components/Auth";
import Admin from "./components/Admin/AdminLogin";
import Dashboard from "./components/Dashboard";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AdminAddUser from "./components/Admin/AdminDashboard/AddUser";
import EscalationMatrix from "./components/EscalationMatrix";
import BulkRecharge from "./components/BulkRecharge";
import BulkModify from "./components/BulkModify";
import Activities from "./components/Activities";
import ActivityDetail from "./components/Activities/ActivityDetail";
import TemporarilySuspend from "./components/TemporarilySuspend";
import ServiceRequest from "./components/ServiceRequest";
import TransactionHistory from "./components/TransactionHistory";
import ResetPassword from "./components/ResetPassword";
import NoPageFound from "./components/NoPageFound";

//Route handling
import {Provider} from 'react-redux';
import store from './store/store';
import {createBrowserHistory} from 'history';
import httpService from './axios-interceptor';
import SomethingWentWrong from "./components/SomethingWentWrong";
import TransactionStatus from "./components/TransactionStatus";
import MyProfile from "./components/MyProfile";
import Header from "./components/Common/Header";
import Footer from "./components/Common/Footer";

const history = createBrowserHistory();
httpService.setupInterceptors(store, history);

const Routes = () => {
  const PrivateRoute = ({ component, ...rest }) => (
    <Router history={history}>
      {
        (history.location.pathname === PATHS.SOMETHING_WENT_WRONG) ||
        (history.location.pathname === PATHS.SERVER_ERROR) ? null : <Header />
      }
      <Route {...rest} render={props => (
        isAuthenticated() ? (
              <Switch>
            <Route exact path="/" render={() => <Redirect to={PATHS.HOME} />} />
            <CommonLayout
              path={PATHS.HOME}
              component={Dashboard}
              exact
            />
            {/*<CommonLayout*/}
            {/*    path={PATHS.DASHBOARD}*/}
            {/*    component={Dashboard}*/}
            {/*    exact*/}
            {/*/>*/}
            <CommonLayout
              path={PATHS.ESCALATION_MATRIX}
              component={EscalationMatrix}
              exact
            />
            <CommonLayout
              path={PATHS.BULK_RECHARGE}
              component={BulkRecharge}
              exact
            />
            <CommonLayout
              path={PATHS.BULK_MODIFY}
              component={BulkModify}
              exact
            />
            <CommonLayout
              path={PATHS.ACTIVITIES}
              component={Activities}
              exact
            />
            <CommonLayout
              path={PATHS.ACTIVITY_DETAIL}
              component={ActivityDetail}
              exact
            />
            <CommonLayout
              path={PATHS.TEMPORARILY_SUSPEND}
              component={TemporarilySuspend}
              exact
            />
            <CommonLayout
              path={PATHS.SERVICE_REQUEST}
              component={ServiceRequest}
              exact
            />
            <CommonLayout
              path={PATHS.TRANSACTION_HISTORY}
              component={TransactionHistory}
              exact
            />
            <CommonLayout
              path={PATHS.SERVER_ERROR}
              component={SomethingWentWrong}
              exact
              serverError
            />
            <CommonLayout
              path={PATHS.SOMETHING_WENT_WRONG}
              component={SomethingWentWrong}
              exact
            />
            <CommonLayout
              path={PATHS.TRANSACTION_STATUS}
              component={TransactionStatus}
              exact
            />
            <CommonLayout
              path={PATHS.PROFILE}
              component={MyProfile}
              exact
            />
            <CommonLayout
              path={PATHS.ADMIN_DASHBOARD}
              component={AdminDashboard}
              exact
            />
            <CommonLayout
              path={PATHS.ADMIN_ADD_USER}
              component={AdminAddUser}
              exact
            />            
            <Route
              component={NoPageFound}
            />
          </Switch>
          
        ) : (
          <Redirect to={{
            pathname: PATHS.LOGIN,
            state: { from: props.location },
          }}
          />
        )
      )}/>
        
      {
        (history.location.pathname === PATHS.SOMETHING_WENT_WRONG) ||
        (history.location.pathname === PATHS.SERVER_ERROR) ? null : <Footer />
      }
    </Router>
  );
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <CommonLayout
            path={PATHS.LOGIN}
            component={Auth}
            exact
          />
            <CommonLayout
                path={PATHS.RESET_PASSWORD}
                component={ResetPassword}
            />
            <CommonLayout
                path={PATHS.ADMIN}
                component={Admin}
            />
            
            <PrivateRoute/>
        </Switch>
      </Router>
    </Provider>
  )
};

export default Routes;
