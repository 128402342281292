import React, {PureComponent} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {closeModal} from "../../../storeManager/modal/actions";
import connect from "react-redux/es/connect/connect";

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: bindActionCreators(closeModal, dispatch),
  }
};
class CommonLayout extends PureComponent {
    _isMounted = false;

    componentDidUpdate(prevProps) {
      const { closeModal } = this.props;
        this._isMounted = true;
        if (this.props.location.pathname !== prevProps.location.pathname) {
            closeModal();
            localStorage.removeItem("reChKey");//isRecharge
            localStorage.removeItem("blkRcTxnId");//bulkRechargeTransactionId
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {component: Component, ...rest} = this.props;
        return (
            <Route
                {...rest}
                render={matchProps => (
                    <React.Fragment>
                        <Component
                            {...rest}
                            {...matchProps}
                        />
                    </React.Fragment>
                )}
            />
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(CommonLayout));