import React, {Component} from 'react';
import {connect} from 'react-redux';
import './addUser.scss';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import Modal from "../../../Common/Modal";
import {MODALS} from '../../../../storeManager/modal/constants';
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import AddOrEditUser from '../AddOrEditUser';
import {isAdminAuthenticated} from '../../../../helper';
import {PATHS} from "../../../../constants";
class AdminAddUser extends Component {
    state = {
        isLoading: false,
        item:{},
    };
    componentDidMount(){
        if(!isAdminAuthenticated()){
            const {history} = this.props;
            history.push(PATHS.ADMIN)
        }
    }

    addNewUser = async (email, name, phone, status, password) => {
        this.setState({isLoading: true});
        let item = {
            email, name, phone, status, password
        }
        const payload = {
            "email": email,
            "name": name,
            "password": password,
            "phone": phone,
            "status": status
        };
        try {
            const response = await commonService.addNewUser(payload);
            const responseData = response.data;
                if(responseData.status){
                    item = {}
                }  
                 this.setState({isLoading:false, item});     
                const {openModal} = this.props;
                openModal(MODALS.CONFIRMATION_POPUP,
                    {
                        message: responseData.message ,
                        reDirectLinkHide: true,
                        // hideCloseButton: true,
                        redirectLoginPage: true,
                    })
                
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    render() {
        const {isLoading, item} = this.state;

        if(isLoading){
            return <Loader />        
        }

        return (
            <div className="content-wrapper">
            <div className="wrapper">
            <div className="row mrl0 justify-content-center">
                <div className="col-12 col-xl-5 prl0 addNewUser">
                    <h2>Add new user</h2>                  
        <AddOrEditUser userData= {item} addUser={true} addNewUser={this.addNewUser}/>
            <Modal/>    
        </div>
        </div>
        </div>
        </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default withRouter(connect(null, mapDispatchToProps)(AdminAddUser));
