import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import Modal from "../../../Common/Modal";
import {MODALS} from '../../../../storeManager/modal/constants';
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import InputsValidator from "../../../../services/FormValidator/InputsValidator";
import {get, debounce} from "lodash";
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import Error from "../../../Common/Error";
import Tooltip from "../../../Common/Tooltip";
import {INPUT_ERROR} from "../../../../constants";

class SalesUser extends Component {
    updateError = null;
    state = {
        chainId: '',
        password: '',
        success: '',
        error: {},
        errorMsg: '',
        isLoading: false,
        confirmPassword: '',

    };
    componentDidMount() {
        this.firstInput.focus();
    }

      adminInputChangeHandler = (event) => {
        const {error} = this.state;
        this.setState({responseMsg: ""});
        let {name, value} = event.target;
        const updateState = {[name] : value};
        this.setState(updateState, () => {
            get(this.updateError, 'cancel') && this.updateError.cancel();
            this.updateError = debounce(() => {
                const validateError = this.validateInput(name, value);
                this.setState({
                    error: {
                        ...error,
                        [(validateError && validateError.name) || name]: validateError
                    }
                });
            }, 100);
            this.updateError();
        });
    };

    validateInput = (name) => {
        const {
            [name]: validateKey,
            password,
            confirmPassword
        } = this.state;
        switch (name) {
            case 'password':
                return InputsValidator.validatePassword(validateKey, confirmPassword, "confirmPassword") || {};
            case 'confirmPassword':
                return InputsValidator.validateConfirmPassword(validateKey, password) || {};
            case 'chainId':
                return InputsValidator.validateChainId(validateKey) || {};
            default:
                return null;
        }
    };

    resetUserPassword = (event) => {
        this.setState({isLoading: true});
        event.preventDefault();
        const {chainId, password} = this.state;
        this.resetPassword(chainId, password)
    };

    resetPassword = async (chainId, password) => {
        this.setState({isLoading: true});

        try {
            const responseOtp = await commonService.resetUserPasswordOtp();
            const responseOtpData = responseOtp.data;
            this.setState({isLoading: false});
            const {openModal} = this.props;

            if(responseOtpData.status){
                openModal(MODALS.OTP_MODAL,
                    {
                        message: responseOtpData.message,
                        newPassword: password,
                        chainId: chainId,
                        reDirectLinkHide: true,
                        // hideCloseButton: true,
                        redirectLoginPage: true,
                    })
                    this.setState({chainId:'', password:'', confirmPassword:''})   
            } else {
                openModal(MODALS.CONFIRMATION_POPUP,
                    {
                        message: responseOtpData.message,
                        reDirectLinkHide: true,
                        // hideCloseButton: true,
                        redirectLoginPage: true,
                    })
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    render() {
        const {chainId, password, confirmPassword, error, isLoading, errorMsg} = this.state;
        const {adminInputChangeHandler, resetUserPassword} = this;
        return (
                <div className="content-wrapper">
                {
                    isLoading && <Loader/>
                }
                <div className="wrapper">
                <div className="row mrl0 justify-content-center">
                    <div className="col-12 col-xl-5 prl0 ">
                        <h2>Change Password</h2>
                        <p>Input the <span className="medium-strong prl5">Chain ID</span>
                         for which password has to be changed 
                        </p>
                   

                <form onSubmit={(e) => resetUserPassword(e)}>
                    
                <div
                    className={(get(error, 'chainId.error')) ? "form-element error mb25" : "form-element mb25"}>
                        <input
                            type="text"
                            className="input-element"
                            placeholder="Enter your Chain ID"
                            maxLength="10"
                            name="chainId"
                            value={chainId}
                            autoComplete="off"
                            ref={(input) => {
                                this.firstInput = input;
                            }}
                            onChange={adminInputChangeHandler}
                            // onKeyPress={restrictDecimalInput}
                        />
                        <i className="info-icon">
                            <svg width="20" height="20" viewBox="0 0 28 28">
                                <defs>
                                    <linearGradient id="infoGradient" x1="100%" x2="0%" y1="0%" y2="0%">
                                        <stop offset="0%" stopColor="#A63283"/>
                                        <stop offset="100%" stopColor="#EA1B2D"/>
                                    </linearGradient>
                                </defs>
                                <path fill="#CCC" fillRule="evenodd"
                                        d="M13.515.014C6.059.014.014 6.059.014 13.514c0 7.457 6.045 13.502 13.501 13.502 7.456 0 13.501-6.045 13.501-13.502 0-7.455-6.045-13.5-13.501-13.5zm2.81 20.924c-.694.274-1.248.482-1.663.626-.413.144-.894.217-1.442.217-.842 0-1.496-.208-1.963-.616-.466-.411-.698-.931-.698-1.563 0-.246.017-.498.051-.753.036-.256.092-.544.168-.868l.87-3.071c.077-.297.143-.577.195-.838.053-.263.078-.504.078-.723 0-.391-.081-.666-.242-.82-.163-.154-.471-.23-.929-.23-.224 0-.455.033-.692.103a9.249 9.249 0 0 0-.605.202l.23-.947a21.55 21.55 0 0 1 1.635-.595 4.825 4.825 0 0 1 1.475-.25c.835 0 1.479.204 1.934.606.451.404.679.928.679 1.573 0 .134-.016.369-.048.705a4.735 4.735 0 0 1-.174.928l-.865 3.063c-.07.246-.134.527-.19.84a4.36 4.36 0 0 0-.084.718c0 .405.09.683.273.832.181.147.498.222.944.222.212 0 .449-.038.717-.113.266-.073.458-.139.578-.194l-.232.946zm-.153-12.433a2.068 2.068 0 0 1-1.458.562 2.09 2.09 0 0 1-1.462-.562c-.405-.375-.61-.831-.61-1.364 0-.531.206-.988.61-1.367a2.073 2.073 0 0 1 1.462-.568c.569 0 1.055.189 1.458.568.403.379.606.836.606 1.367 0 .534-.203.989-.606 1.364z"/>
                            </svg>
                        </i>
                        {
                            get(error, 'chainId.error') ? (
                                <div className="error-section">
                                    <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 25 25">
                                            <defs>
                                                <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                    <stop offset="0%" stopColor="#A63283"/>
                                                    <stop offset="100%" stopColor="#EA1B2D"/>
                                                </linearGradient>
                                            </defs>
                                            <path fill="#51B684" fillRule="evenodd"
                                                    d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                            <path fill="url(#error)"
                                                    d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                        </svg>
                                    </i>
                                    <div className="error-message">
                                        {
                                            get(error, 'chainId.message')
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                        <Tooltip
                            message="Please Enter your Chain ID."
                        />
                    </div>
                            <div className={get(error, 'password.error') ? "form-element mb25 error" : "form-element mb25"}>
                                <input
                                    type="password"
                                    className="input-element"
                                    placeholder="New Password"
                                    name="password"
                                    value={password}
                                    onChange={adminInputChangeHandler}
                                    autoComplete="off"
                                />
                                <i className="info-icon">
                                    <svg width="20" height="20" viewBox="0 0 28 28">
                                        <path fill="#CCC" fillRule="evenodd"
                                                d="M13.515.014C6.059.014.014 6.059.014 13.514c0 7.457 6.045 13.502 13.501 13.502 7.456 0 13.501-6.045 13.501-13.502 0-7.455-6.045-13.5-13.501-13.5zm2.81 20.924c-.694.274-1.248.482-1.663.626-.413.144-.894.217-1.442.217-.842 0-1.496-.208-1.963-.616-.466-.411-.698-.931-.698-1.563 0-.246.017-.498.051-.753.036-.256.092-.544.168-.868l.87-3.071c.077-.297.143-.577.195-.838.053-.263.078-.504.078-.723 0-.391-.081-.666-.242-.82-.163-.154-.471-.23-.929-.23-.224 0-.455.033-.692.103a9.249 9.249 0 0 0-.605.202l.23-.947a21.55 21.55 0 0 1 1.635-.595 4.825 4.825 0 0 1 1.475-.25c.835 0 1.479.204 1.934.606.451.404.679.928.679 1.573 0 .134-.016.369-.048.705a4.735 4.735 0 0 1-.174.928l-.865 3.063c-.07.246-.134.527-.19.84a4.36 4.36 0 0 0-.084.718c0 .405.09.683.273.832.181.147.498.222.944.222.212 0 .449-.038.717-.113.266-.073.458-.139.578-.194l-.232.946zm-.153-12.433a2.068 2.068 0 0 1-1.458.562 2.09 2.09 0 0 1-1.462-.562c-.405-.375-.61-.831-.61-1.364 0-.531.206-.988.61-1.367a2.073 2.073 0 0 1 1.462-.568c.569 0 1.055.189 1.458.568.403.379.606.836.606 1.367 0 .534-.203.989-.606 1.364z"/>
                                    </svg>
                                </i>
                                <Tooltip
                                    message={INPUT_ERROR.PATTERN_PASSWORD}
                                />
                                {
                                    get(error, 'password.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                            d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                            d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {get(error, 'password.message')}
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
        
                            <div
                                className={get(error, 'confirmPassword.error') ? "form-element mb25 error" : "form-element mb25"}>
                                <input
                                    type="password"
                                    className="input-element"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={adminInputChangeHandler}
                                    autoComplete="off"
                                />
        
                                {
                                    get(error, 'confirmPassword.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                            d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                            d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {get(error, 'confirmPassword.message')}
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                    
                    

                    {
                        errorMsg && <Error error={errorMsg}/>
                    }

                    <button
                        type="submit"
                        className="button fill full big mb30 "
                        onClick={(e) => resetUserPassword(e)}
                        disabled={!chainId || !password || !confirmPassword}
                    >
                        Continue
                    </button>
                    </form>
                    </div>
                    </div>
                </div>
                <Modal/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default withRouter(connect(null, mapDispatchToProps)(SalesUser));