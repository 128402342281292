import React, {Component} from 'react';

class Search extends Component {
  state = {
    searchValue: ""
  };

  onChangeHandler = (searchValue) => {
    const { onChangeSearchHandler } = this.props;
    let value = searchValue.replace(/[^0-9]/g, '');
    onChangeSearchHandler(value);
    this.setState({
      searchValue: value
    });
  };

  removeSearchValue = () => {
    const { onChangeSearchHandler } = this.props;
    onChangeSearchHandler("");
    this.setState({
      searchValue: ""
    });
  };

  render() {
    const { placeHolder, disabled } = this.props;
    const { searchValue } = this.state;
    return (
      <div className="search-container">
        <div className="form-element">
          <input
            type="text"
            className="input-element big"
            placeholder={placeHolder}
            name="searchValue"
            value={searchValue}
            maxLength={10}
            autoComplete="off"
            disabled={disabled}
            onChange={(e)=>this.onChangeHandler(e.target.value)}
          />
          <i className="search-icon">
            <svg width="21" height="21">
              <path
                fill="#67757D"
                fillRule="evenodd"
                d="M7.408 0h1.837c.099.021.197.047.298.063 6.049.956 9.079 7.672 5.773 12.813-.141.219-.113.342.063.516 1.65 1.639 3.29 3.289 4.94 4.929.286.284.54.582.681.965v.612A1.709 1.709 0 0 1 19.898 21h-.612c-.393-.145-.696-.409-.987-.703-1.632-1.643-3.275-3.275-4.907-4.919-.174-.175-.297-.205-.517-.062-1.413.925-2.967 1.407-4.659 1.344-3.485-.128-5.976-1.804-7.505-4.923C.326 10.952.15 10.1 0 9.245V7.408c.021-.099.046-.198.064-.298C.602 4.056 2.281 1.89 5.113.623 5.844.296 6.626.144 7.408 0zm7.271 8.332c.005-3.488-2.866-6.36-6.355-6.358-3.48.002-6.334 2.846-6.35 6.331-.018 3.485 2.844 6.363 6.339 6.374 3.486.011 6.362-2.856 6.366-6.347z"
              />
            </svg>
          </i>
          {
            searchValue && !disabled && (
              <i className="search-icon search-cross-icon" onClick={()=>this.removeSearchValue()}>
                <svg width="16" height="16" viewBox="0 0 25 25">
                  <defs>
                    <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                      <stop offset="0%" stopColor="#A63283"/>
                      <stop offset="100%" stopColor="#EA1B2D"/>
                    </linearGradient>
                  </defs>
                  <path fill="#51B684" fillRule="evenodd"
                        d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                  <path fill="url(#error)"
                        d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                </svg>
              </i>
            )
          }
        </div>
      </div>
    )
  }
}

export default Search;