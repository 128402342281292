import React, {Component, Fragment} from 'react';
import EditRechargeTable from './EditRechargeTable';
import '../bulk-recharge.scss';
import TableComponent from "../../Common/TableComponent";

class SubscriberDetails extends Component {

    render() {
        const {
            isDisabledCheckedAll,
            isCheckedAll,
            isSubmitted,
            customerDetails,
            isTotalEdit,
            showSubmitBtn,
            totalAmount,
            selectAllSubId,
            handleRechargeBtn,
            checkboxHandler,
            updateTotalAmount,
            sortHandler
        } = this.props;

        return (
            <Fragment>
                <div className="d-flex align-items-center action-row p20">
                    {
                        !isSubmitted ? (
                            <Fragment>
                                <label className="checkbox-element p10">
                                    <input
                                        id="checkUncheckAll"
                                        type="checkbox"
                                        name="recharge"
                                        checked={isCheckedAll}
                                        onChange={() => selectAllSubId()}
                                        disabled={isDisabledCheckedAll}
                                    />
                                    <span className="checkmark"/>
                                </label>
                                {
                                    (isCheckedAll || showSubmitBtn) &&
                                    <button
                                        type="button"
                                        className="button shadow white-bg w-140 ml15"
                                        onClick={() => handleRechargeBtn('isSubmitted')}
                                    >
                                        Submit
                                    </button>
                                }
                            </Fragment>
                        ) : (
                            <div className="total-amount d-flex align-items-center">
                                Total Amount for Recharge:
                                <div className="rupees pl10">
                                    <span className="prl5">
                                        &#8377;
                                    </span>
                                    {
                                        isTotalEdit ?
                                            <div className="form-element pl5">
                                                <input
                                                    type="text"
                                                    name="edit-total"
                                                    value={totalAmount}
                                                    className="input-element"
                                                />
                                            </div> :
                                            <span>
                                                {totalAmount}
                                            </span>}
                                </div>
                                {/*<i className="edit-icon ml10" onClick={this.handleTotalEdit}>
                                    <svg width="15" height="15">
                                        <path fill="#FFF" fillRule="evenodd"
                                              d="M14.527 2.463L12.566.503c-.56-.56-1.47-.56-1.96 0L.943 10.096v3.992h3.991l9.593-9.664c.771-.77.28-1.68 0-1.961zM4.304 12.687H2.343v-1.96l7.282-7.283 1.961 1.961-7.282 7.282zm8.262-8.263l-1.96-1.961.98-.98 1.961 1.961-.981.98z"
                                        />
                                    </svg>
                                </i>*/}
                                <button
                                    type="button"
                                    className="button white-bg w-140 shadow ml30"
                                    onClick={() => handleRechargeBtn('isRecharge')}
                                    disabled={!totalAmount}
                                >
                                    Recharge
                                </button>
                            </div>
                        )
                    }
                </div>
                {
                    !isSubmitted ?
                      <TableComponent
                        tableData={customerDetails}
                        checkBox
                        checkboxHandler={checkboxHandler}
                        tableClass={"recharge-details"}
                        isVisibleCheck
                        sorting
                        sortHandler={sortHandler}
                      /> :
                        <EditRechargeTable
                            tableData={customerDetails}
                            checkboxHandler={checkboxHandler}
                            updateTotalAmount={updateTotalAmount}
                        />
                }
            </Fragment>
        );
    }
}

export default SubscriberDetails;
