export const API_PATH = {
  production: {
    API_ROOT: `${window.location.origin}/tataskyB2B`,
    // S3_API_ROOT: `${window.location.origin}`,
  },
  development: {
    API_ROOT: 'https://uatb2b.tatasky.com/tataskyB2B',
    // API_ROOT: 'https://d2e7djtyrtdgdv.cloudfront.net/tataskyB2B',
    // API_ROOT: 'https://d28hbf6asm2qph.cloudfront.net/tataskyB2B',
    // S3_API_ROOT: '',
  },
};

// export const logoLink = '';
