import {ACTION} from "./constants";

export const resetReduxState = () => (dispatch) => {
  dispatch({
    type: ACTION.RESET_STATE,
  })
};

/*
export const logout = () => (dispatch) => {
  dispatch({
    type: ACTION.LOGOUT,
  })
};*/
