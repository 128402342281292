import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {closeModal, openModal as openModalHandler} from "../../../../../storeManager/modal/actions";
import InputsValidator from '../../../../../services/FormValidator/InputsValidator';
import {commonService} from "../../../../../storeManager/common/services";
import GlobalConfig from "../../../../../globalConfig/globalConfig";
import Loader from "../../../../Loader";
import {PATHS, INPUT_ERROR} from "../../../../../constants";
import Error from "../../../../Common/Error";
import {restrictDecimalInput} from '../../../../../helper';
import {get, debounce} from "lodash";
import Tooltip from "../../../../Common/Tooltip";

class AdminOTPModal extends Component {
    state = {
        emailOrPhone: '',
        otp: '',
        error: '',
        errorMsg: {},
        success: '',
        password: '',
        isLoading: false,
        resendOtp: true,
        showEmailOrPhoneField: true,
        confirmPassword: '',
    };

    componentDidMount() {
        this.inputFocus.focus();
    }

    adminInputChangeHandler = (event) => {
        let {name, value} = event.target;
        if (name === 'otp') {
            if (!isNaN(value)) {
                this.setState({
                    [name]: value,
                    errorMsg: {}
                })
            }
        } else {
        const updateState = {[name] : value};
        this.setState(updateState, () => {
            get(this.updateError, 'cancel') && this.updateError.cancel();
            this.updateError = debounce(() => {
                const validateError = this.validateInput(name) || {};
                this.setState({
                    errorMsg: {
                        [name]: validateError
                    }
                })
            }, 100);
            this.updateError();
        });
    }
    };

    validateInput = (name) => {
        const {
            [name]: validateKey,
            password,
            confirmPassword
        } = this.state;
        switch (name) {
            case 'password':
                return InputsValidator.validatePassword(validateKey, confirmPassword, "confirmPassword") || {};
            case 'confirmPassword':
                return InputsValidator.validateConfirmPassword(validateKey, password) || {};
            case 'emailOrPhone':
                return  isNaN(validateKey) ? 
                InputsValidator.validateEmail(validateKey)
                :
                InputsValidator.validatePhoneNumber(validateKey)
            default:
                return null;
        }
    };

    validateOTP = async () => {
        const {otp, emailOrPhone, password} = this.state;
      const { history, closeModal} = this.props;
      const payload = {
          "newPassword": password,
          "otp": otp,
          "emailOrPhone": emailOrPhone, //used to create token
      };
      try {
        const response = await commonService.validateAdminOTPStatus(payload);
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {

          closeModal();
          history.push(PATHS.ADMIN);
        } else {
          this.setState({error: responseData.message, isLoading: false})
        }
      } catch {
        this.setState({error: GlobalConfig.serverError, isLoading: false})
      }
    };


    generateOtpForRMN = async () => {
        const {emailOrPhone} = this.state;
        this.setState({error: '', isLoading: true, otp: ''});
        const payload = {
            emailOrPhone
        };
        try {
            const response = await commonService.generateOtpAdmin(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false,  showEmailOrPhoneField: false}) //resendOtp: responseData.data.resendOtp
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }

    };

    verifyUser = (e) => {
        e.preventDefault();
        const { showEmailOrPhoneField } = this.state;
      this.setState({isLoading: true});
      if(showEmailOrPhoneField){
          this.generateOtpForRMN();
      }  else {
        this.validateOTP();
      }
    };

    render() {
        const {otp, error, errorMsg, confirmPassword, emailOrPhone, password, success, isLoading, resendOtp, showEmailOrPhoneField} = this.state;
        const {adminInputChangeHandler, verifyUser, generateOtpForRMN} = this;
        const maxLength = isNaN(emailOrPhone) || emailOrPhone === '' ? 50 :  10

        const OTPLink = (
            <div className="text-btn ptb15" onClick={generateOtpForRMN}>
              Resend OTP
            </div>
        );

        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <div className="b2b-modal-header center pb20">
                    <div className="gradient-text">
                        {
                              showEmailOrPhoneField ? 'Enter Your Email Id Or Phone' : 'Reset Your Password'
                        }
                    </div>
                </div>

                <div className="prl50">
                    <form onSubmit={(e) => verifyUser(e)}>
                        {
                          showEmailOrPhoneField ? (
                                <Fragment>
                                                              <div
                                className={(get(errorMsg, 'emailOrPhone.error')) ? 'form-element mb30 error' : 'form-element mb30'}>
                                <input
                                    type="text"
                                    className="input-element"
                                    placeholder="Enter email or Phone"
                                    autoComplete="off"
                                    maxLength={ maxLength }
                                    name="emailOrPhone"
                                    value={emailOrPhone}
                                    ref={(input) => {
                                        this.inputFocus = input;
                                      }}
                                    onChange={adminInputChangeHandler}
                                    // onKeyPress={restrictDecimalInput}
                                />
                                {
                                    get(errorMsg, 'emailOrPhone.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {
                                                    get(errorMsg, 'emailOrPhone.message')
                                                }
                                            </div>
                                        </div>
                                    ) : success ? (
                                        <div className="success-section">
                                            <i className="icon">
                                                <svg width="22" height="16" viewBox="0 0 27 19">
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M27 2.471c-.134.4-.405.703-.71.994-1.743 1.668-3.478 3.344-5.218 5.015-2.12 2.036-4.242 4.07-6.364 6.104-1.136 1.089-2.275 2.176-3.411 3.264-.858.821-1.823.832-2.688.006-1.65-1.577-3.29-3.164-4.936-4.745-.979-.94-1.957-1.881-2.946-2.811C.407 9.996.135 9.673 0 9.254V8.54c.329-.854.915-1.301 1.694-1.283.521.012.942.237 1.304.586 1.599 1.54 3.199 3.081 4.8 4.619.642.617 1.293 1.224 1.928 1.846.173.169.277.183.46.004 1.331-1.295 2.673-2.581 4.013-3.868a9984.84 9984.84 0 0 1 6.642-6.371c1.054-1.011 2.138-1.994 3.158-3.036.928-.949 2.558-.686 2.966.6.008.025.023.046.035.069v.765z"/>
                                                </svg>
                                            </i>
                                        </div>
                                    ) : null
                                }
                            </div>



                                </Fragment>
                            ) : (
                                <Fragment>
                                    {
                                          <Fragment>
                                
                    <div className={get(errorMsg, 'password.error') ? "form-element mb25 error" : "form-element mb25"}>
                        <input
                            type="password"
                            className="input-element"
                            placeholder="New Password"
                            name="password"
                            value={password}
                            onChange={adminInputChangeHandler}
                            autoComplete="off"
                        />
                        <i className="info-icon">
                            <svg width="20" height="20" viewBox="0 0 28 28">
                                <path fill="#CCC" fillRule="evenodd"
                                      d="M13.515.014C6.059.014.014 6.059.014 13.514c0 7.457 6.045 13.502 13.501 13.502 7.456 0 13.501-6.045 13.501-13.502 0-7.455-6.045-13.5-13.501-13.5zm2.81 20.924c-.694.274-1.248.482-1.663.626-.413.144-.894.217-1.442.217-.842 0-1.496-.208-1.963-.616-.466-.411-.698-.931-.698-1.563 0-.246.017-.498.051-.753.036-.256.092-.544.168-.868l.87-3.071c.077-.297.143-.577.195-.838.053-.263.078-.504.078-.723 0-.391-.081-.666-.242-.82-.163-.154-.471-.23-.929-.23-.224 0-.455.033-.692.103a9.249 9.249 0 0 0-.605.202l.23-.947a21.55 21.55 0 0 1 1.635-.595 4.825 4.825 0 0 1 1.475-.25c.835 0 1.479.204 1.934.606.451.404.679.928.679 1.573 0 .134-.016.369-.048.705a4.735 4.735 0 0 1-.174.928l-.865 3.063c-.07.246-.134.527-.19.84a4.36 4.36 0 0 0-.084.718c0 .405.09.683.273.832.181.147.498.222.944.222.212 0 .449-.038.717-.113.266-.073.458-.139.578-.194l-.232.946zm-.153-12.433a2.068 2.068 0 0 1-1.458.562 2.09 2.09 0 0 1-1.462-.562c-.405-.375-.61-.831-.61-1.364 0-.531.206-.988.61-1.367a2.073 2.073 0 0 1 1.462-.568c.569 0 1.055.189 1.458.568.403.379.606.836.606 1.367 0 .534-.203.989-.606 1.364z"/>
                            </svg>
                        </i>
                        <Tooltip
                            message={INPUT_ERROR.PATTERN_PASSWORD}
                        />
                        {
                            get(errorMsg, 'password.error') ? (
                                <div className="error-section">
                                    <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 25 25">
                                            <defs>
                                                <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                    <stop offset="0%" stopColor="#A63283"/>
                                                    <stop offset="100%" stopColor="#EA1B2D"/>
                                                </linearGradient>
                                            </defs>
                                            <path fill="#51B684" fillRule="evenodd"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                            <path fill="url(#error)"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                        </svg>
                                    </i>
                                    <div className="error-message">
                                        {get(errorMsg, 'password.message')}
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>

                    <div
                        className={get(errorMsg, 'confirmPassword.error') ? "form-element mb25 error" : "form-element mb25"}>
                        <input
                            type="password"
                            className="input-element"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={adminInputChangeHandler}
                            autoComplete="off"
                        />

                        {
                            get(errorMsg, 'confirmPassword.error') ? (
                                <div className="error-section">
                                    <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 25 25">
                                            <defs>
                                                <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                    <stop offset="0%" stopColor="#A63283"/>
                                                    <stop offset="100%" stopColor="#EA1B2D"/>
                                                </linearGradient>
                                            </defs>
                                            <path fill="#51B684" fillRule="evenodd"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                            <path fill="url(#error)"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                        </svg>
                                    </i>
                                    <div className="error-message">
                                        {get(errorMsg, 'confirmPassword.message')}
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>
                                            <div className="form-element">
                                              <input type="text"
                                                     className="input-element otp-pwd"
                                                     placeholder="OTP"
                                                     maxLength="6"
                                                     name="otp"
                                                     value={otp}
                                                     onChange={adminInputChangeHandler}
                                                     ref={(input) => {
                                                       this.inputFocus = input;
                                                     }}
                                                     autoComplete="off"
                                                     onKeyPress={restrictDecimalInput}
                                              />
                                            </div>
                                            {resendOtp ? OTPLink : null}
                                          </Fragment>
                                        
                                    }
                                </Fragment>
                            )
                        }

                        {
                            error && <Error error={error}/>
                        }

                        <button
                            type="submit"
                            className="button fill big w-140 mt10 mb20"
                            onClick={(e) => verifyUser(e)}
                            disabled={
                                showEmailOrPhoneField ?
                                  (!emailOrPhone || get(errorMsg, 'emailOrPhone.message')) : (!otp || otp.length < 6)
                              }
                        >
                            {
                                showEmailOrPhoneField ? 'Send OTP' : 'Submit'
                            }
                        </button>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(AdminOTPModal));
