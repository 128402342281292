import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/stylesheet/global/_table.scss';

const SelectSubscriber = ({
														tableData,
														checkboxHandler,
														isProceed,
														tableClass,
													}) => {

	return (
		<table className={`table-wrapper ${tableClass}`}>
			<thead>
			<tr>
				{!isProceed ? <th /> : null}
				<th className={`${isProceed && `pl50`}`}>Chain ID</th>
				<th>Subscriber ID</th>
				{isProceed ? <th>Pack Name</th> : null}
			</tr>
			</thead>
			<tbody>
			{tableData && tableData.map((item, key) => {
				return item.isVisible ? (
          <tr key={key}>
          {
        !isProceed ?
          <td className="pl20">
            <label className="checkbox-element">
              <input
                type="checkbox"
                id={key}
                value={item.subscriberId || ''}
                onChange={checkboxHandler}
                checked={item.isChecked}
              />
              <span className="checkmark"/>
            </label>
          </td>
          :
          null
      }
        <td className={`${isProceed && `pl50`}`}>{item.chainId}</td>
        <td>{item.subscriberId}</td>
        {isProceed ? <td>{item.packName}</td> : null}
      </tr>
				) : null
			})}
			</tbody>
		</table>
	)
};

SelectSubscriber.propTypes = {
	tableData: PropTypes.array,
	checkboxHandler: PropTypes.func,
	isProceed: PropTypes.bool
};

export default SelectSubscriber;
