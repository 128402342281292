import GlobalConfig from '../../globalConfig/globalConfig';

export const validateRmnChainIdApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/validateRmnChainId`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const generateOtpApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/generateOtp`,
    // url: `${GlobalConfig.API_ROOT}/generateOtp/test`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const validateOTPApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/validateOtp`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const validatePasswordApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/login`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getSubscriberListApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/subscription/subscribersList`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getBalanceDetailApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/accounts/getBalance`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const redirectModifyPackApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/redirect/modifyPackRequest`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getTransactionsApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/accounts/getTransactionHistory`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getHardResetApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/reset/hardReset`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getSuspendStatusApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/suspend/fetchSuspension`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getSuspendResumeStatusApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/suspend/suspendAccount`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getSRTypePopulateApi = {
    method: 'GET',
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getSRSubTypePopulateApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/serviceRequest/srSubTypePopulate`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getServiceRequestApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/serviceRequest/createSR`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getModifyPack = {
    method: 'GET',
    url: `${GlobalConfig.API_ROOT}/modifyPack/packValues`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getSubscriberListApiWithChainId = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/modifyPack/bulkModifyPackView`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getServiceRequestQueryApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/serviceRequest/serviceQuery`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getRechargeUrlApi = {
    method: 'GET',
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getBulkModifySubmitApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/modifyPack/bulkModifyRequest`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const registerUserApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/signup`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const bulkRechargeListApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/accounts/getBulkRechargeView`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const recentActivitiesApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/activity/bulkActivity`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getActivityDetailApi = {
    method: 'POST',
    url : `${GlobalConfig.API_ROOT}/activity/bulkActivityDetail`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getActivitiesDetailConfirmationApi = {
  method: 'POST',
  url : `${GlobalConfig.API_ROOT}/activity/modifyPackTransactionStatus`,
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const escalationMatrixListApi = {
  method: 'GET',
  url: `${GlobalConfig.API_ROOT}/getEscalationDetails`,
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const changePasswordApi = {
    method: 'POST',
    url : `${GlobalConfig.API_ROOT}/changePassword`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const sendEmailApi = {
    method: 'POST',
    url : `${GlobalConfig.API_ROOT}/forgetPassword`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const viewProfileApi = {
    method: 'GET',
    url : `${GlobalConfig.API_ROOT}/viewProfile`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const clearHardResetApi = {
    method: 'GET',
    url : `${GlobalConfig.API_ROOT}/clearHardReset`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const getChainIdListApi = {
  method: 'POST',
  url: `${GlobalConfig.API_ROOT}/subscription/getChainIdList`,
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const bulkRechargeStatusApi = {
  method: 'POST',
  url: `${GlobalConfig.API_ROOT}/payment/initPayment`,
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const bulkRechargeTransactionStatusApi = {
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const validateResetPasswordTokenApi = {
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const submitResetPasswordApi = {
  method: 'POST',
    url: `${GlobalConfig.API_ROOT}/resetPassword`,
  headers: GlobalConfig.getHeaders(['JSON']),
};

export const adminLoginApi = {
    method: 'POST',
      url: `${GlobalConfig.API_ROOT}/admin/login`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const generateOtpAdminApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/reset/password/otp`,
    // url: `${GlobalConfig.API_ROOT}/generateOtp/test`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const validateAdminOTPApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/validate/otp`,
    // url: `${GlobalConfig.API_ROOT}/generateOtp/test`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const resetUserPasswordApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/change-password`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const resetUserPasswordOtpApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/change-password-otp`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const userListApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/admin-data`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const addNewUserApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/addNewSalesUser`,
    headers: GlobalConfig.getHeaders(['JSON']),
};

export const editUserApi = {
    method: 'POST',
    url: `${GlobalConfig.API_ROOT}/admin/edit-data`,
    headers: GlobalConfig.getHeaders(['JSON']),
};