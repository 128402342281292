import React, {Component, Fragment} from 'react';
import {MESSAGES, PATHS} from "../../constants";
import './transaction-history.scss';
import TableComponent from "../Common/TableComponent";
import GlobalConfig from '../../globalConfig/globalConfig';
import {commonService} from "../../storeManager/common/services";
import Loader from "../Loader";
import {dateFormatter} from "../../helper";

class TransactionHistory extends Component {
    state = {
        transactions: [],
        isLoading: true
    };

    componentDidMount() {
        const { location: {state} } = this.props;
        if(state && state.subscriberId){
            this.getTransactionHistory(state.subscriberId, state.noOfRows)
                .catch(() => {
                    console.error(MESSAGES.DID_MOUNT_ERROR);
                });
        }
    };

    getTransactionHistory = async (subId, noOfRows) => {
        const payload = {
            "subscriberId": subId,
            noOfRows
        };
        try {
            const response = await commonService.transactionHistoryApi(payload);
            const responseData = response.data;
            if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                let data = responseData.data;
                data.map(item => {
                   item.date = dateFormatter(item.date);
                  item.amount = Math.sign(item.amount) === -1 ? <Fragment>
                    {Math.abs(item.amount)}{' '}
                    <span className="dr">(DR)</span>
                    </Fragment> : <Fragment>
                    { item.amount }{' '}
                    <span className="cr">(CR)</span>
                    </Fragment>;
                   return data;
                });
                this.setState({
                    transactions: data,
                    isLoading: false
                })
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    backToDashboard = () => {
        this.props.history.push(PATHS.HOME);
    };

    render() {
        const { backToDashboard } = this;
        const { isLoading, transactions } = this.state;

        return (
            <div className="transaction-history">
                {
                    isLoading && <Loader />
                }
                <div className="content-wrapper pb60">
                    <div className="prev-page-link" onClick={backToDashboard}>
                        <i className="back-link">
                            <svg width="25" height="16">
                                <path fill="currentColor" fillRule="evenodd" d="M8.336.599a.923.923 0 0 1 1.211 0 .703.703 0 0 1 0 1.067L3.406 7.131h20.265c.473 0 .86.334.86.755 0 .42-.387.765-.86.765H3.406l6.141 5.456a.714.714 0 0 1 0 1.077.923.923 0 0 1-1.211 0L.741 8.425a.703.703 0 0 1 0-1.067L8.336.599z" />
                            </svg>
                        </i>
                        Back to Connection List
                    </div>
                    <div className="d-flex monthly-statement pt20 pb25">
                        <h2 className="heading">
                            Transaction History
                        </h2>

                        {/*<div className="statement-btn">*/}
                        {/*    <button type="button" className="button big shadow white-bg">*/}
                        {/*        Get Monthly Statement*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>

                    <TableComponent
                        tableData={transactions}
                        tableClass={"transaction-table"}
                    />
                </div>
            </div>
        )
    }
}

export default TransactionHistory;
