import React, {Component} from 'react';
import {connect} from 'react-redux';
import './login.scss';
import {withRouter} from 'react-router-dom';
import {isAdminAuthenticated} from '../../../../helper';
import {bindActionCreators} from "redux";
import Modal from "../../../Common/Modal";
import {MODALS} from '../../../../storeManager/modal/constants';
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import InputsValidator from "../../../../services/FormValidator/InputsValidator";
import {get, debounce} from "lodash";
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import {PATHS} from "../../../../constants";
import Error from "../../../Common/Error";
import {setLocalStorage} from '../../../../services/commonUtils';
// import {restrictDecimalInput} from './../../../helper';

class Login extends Component {
    updateError = null;
    state = {
        emailOrPhone: '',
        password: '',
        success: '',
        error: {},
        errorMsg: '',
        isLoading: false,
    };

    componentDidMount() {
        const {history} = this.props;
        if (isAdminAuthenticated()) {
            history.push(PATHS.ADMIN_DASHBOARD)
        }
        this.loginInput.focus();
    }

      adminInputChangeHandler = (event) => {
        const {error} = this.state;
        this.setState({responseMsg: ""});
        let {name, value} = event.target;
        const updateState = {[name] : value};
        this.setState(updateState, () => {
            get(this.updateError, 'cancel') && this.updateError.cancel();
            this.updateError = debounce(() => {
                const validateError = this.validateInput(name, value);
                this.setState({
                    error: {
                        ...error,
                        [(validateError && validateError.name) || name]: validateError
                    }
                });
            }, 100);
            this.updateError();
        });
    };
  
    validateInput = (name) => {
        const {
            [name]: validateKey
        } = this.state;
        switch (name) {
            case 'password':
                return InputsValidator.validatePassword(validateKey) || {};
            case 'emailOrPhone':
                return  isNaN(validateKey) ? 
                InputsValidator.validateEmail(validateKey)
                :
                InputsValidator.validatePhoneNumber(validateKey)
            default:
                return null;
        }
    };

    authenticateUser = (event) => {
        if (!isAdminAuthenticated()) {
            this.setState({isLoading: true});
            event.preventDefault();
            const {emailOrPhone, password} = this.state;
            this.adminLogin(emailOrPhone, password)
        } else {
            const {history} = this.props;
            history.push(PATHS.ADMIN_DASHBOARD);
        }
    };

    adminLogin = async (emailOrPhone, password) => {
        const {history} = this.props;
        const payload = {
                emailOrPhone,
                password,
        };
        try {
            const response = await commonService.adminLogin(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
               this.setState({isLoading: false});
                if(responseData.data) {
                    const adminData = {
                        'emailId': responseData.data.emailId,
                        'phone': responseData.data.phone,
                        'role': responseData.data.role,
                        'name' : responseData.data.name,
                    }
                    localStorage.setItem("token", `Bearer ${get(responseData.data, "token")}`);
                    setLocalStorage("admin", adminData)
                    history.push(PATHS.ADMIN_DASHBOARD);
                } else {
                    this.setState({errorMsg: responseData.message, isLoading: false})
                }
            } else {
                this.setState({errorMsg: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    resetPassword = (e) =>{
        e.preventDefault(e);
        const {openModal} = this.props;
        const {emailOrPhone} = this.state;

        openModal(MODALS.ADMIN_OTP_MODAL, {emailOrPhone});
    }


    render() {
        const {emailOrPhone, password, error, success, isLoading, errorMsg} = this.state;
        const {adminInputChangeHandler, authenticateUser} = this;
        const maxLength = isNaN(emailOrPhone) || emailOrPhone === '' ? 50 :  10
        return (
            <div className="login-container">
                {
                    isLoading && <Loader/>
                }

                <form onSubmit={(e) => authenticateUser(e)}>
                    
                            <div
                                className={(get(error, 'emailOrPhone.error')) ? 'form-element mb30 error' : 'form-element mb30'}>
                                <input
                                    type="text"
                                    className="input-element"
                                    placeholder="Enter email or Phone"
                                    autoComplete="off"
                                    maxLength={ maxLength}
                                    name="emailOrPhone"
                                    value={emailOrPhone}
                                    ref={(input) => {
                                        this.loginInput = input;
                                    }}
                                    onChange={adminInputChangeHandler}
                                    // onKeyPress={restrictDecimalInput}
                                />
                                {
                                    get(error, 'emailOrPhone.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {
                                                    get(error, 'emailOrPhone.message')
                                                }
                                            </div>
                                        </div>
                                    ) : success ? (
                                        <div className="success-section">
                                            <i className="icon">
                                                <svg width="22" height="16" viewBox="0 0 27 19">
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M27 2.471c-.134.4-.405.703-.71.994-1.743 1.668-3.478 3.344-5.218 5.015-2.12 2.036-4.242 4.07-6.364 6.104-1.136 1.089-2.275 2.176-3.411 3.264-.858.821-1.823.832-2.688.006-1.65-1.577-3.29-3.164-4.936-4.745-.979-.94-1.957-1.881-2.946-2.811C.407 9.996.135 9.673 0 9.254V8.54c.329-.854.915-1.301 1.694-1.283.521.012.942.237 1.304.586 1.599 1.54 3.199 3.081 4.8 4.619.642.617 1.293 1.224 1.928 1.846.173.169.277.183.46.004 1.331-1.295 2.673-2.581 4.013-3.868a9984.84 9984.84 0 0 1 6.642-6.371c1.054-1.011 2.138-1.994 3.158-3.036.928-.949 2.558-.686 2.966.6.008.025.023.046.035.069v.765z"/>
                                                </svg>
                                            </i>
                                        </div>
                                    ) : null
                                }
                            </div>


                            <div
                                className={(get(error, 'password.error')) ? 'form-element mb30 error' : 'form-element mb30'}>
                                    <input type="password"
                                           className="input-element"
                                           placeholder="Password"
                                           name="password"
                                           value={password}
                                           onChange={adminInputChangeHandler}
                                           ref={(input) => {
                                             this.inputFocus = input;
                                           }}
                                           autoComplete="off"
                                    />
                                {
                                    get(error, 'password.error') ? (
                                        <div className="error-section">
                                            <i className="icon">
                                                <svg width="20" height="20" viewBox="0 0 25 25">
                                                    <defs>
                                                        <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                            <stop offset="0%" stopColor="#A63283"/>
                                                            <stop offset="100%" stopColor="#EA1B2D"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                    <path fill="url(#error)"
                                                          d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                                </svg>
                                            </i>
                                            <div className="error-message">
                                                {
                                                    get(error, 'password.message')
                                                }
                                            </div>
                                        </div>
                                    ) : success ? (
                                        <div className="success-section">
                                            <i className="icon">
                                                <svg width="22" height="16" viewBox="0 0 27 19">
                                                    <path fill="#51B684" fillRule="evenodd"
                                                          d="M27 2.471c-.134.4-.405.703-.71.994-1.743 1.668-3.478 3.344-5.218 5.015-2.12 2.036-4.242 4.07-6.364 6.104-1.136 1.089-2.275 2.176-3.411 3.264-.858.821-1.823.832-2.688.006-1.65-1.577-3.29-3.164-4.936-4.745-.979-.94-1.957-1.881-2.946-2.811C.407 9.996.135 9.673 0 9.254V8.54c.329-.854.915-1.301 1.694-1.283.521.012.942.237 1.304.586 1.599 1.54 3.199 3.081 4.8 4.619.642.617 1.293 1.224 1.928 1.846.173.169.277.183.46.004 1.331-1.295 2.673-2.581 4.013-3.868a9984.84 9984.84 0 0 1 6.642-6.371c1.054-1.011 2.138-1.994 3.158-3.036.928-.949 2.558-.686 2.966.6.008.025.023.046.035.069v.765z"/>
                                                </svg>
                                            </i>
                                        </div>
                                    ) : null
                                }
                            </div>
                    {
                        errorMsg && <Error error={errorMsg}/>
                    }

                    <button
                        type="submit"
                        className="button fill full big mt30"
                        onClick={(e) => authenticateUser(e)}
                        disabled={[emailOrPhone, password].includes('')}
                    >
                        Continue
                    </button>
                    <p style={{marginTop:'20px', textAlign:'right'}}>Forgot Password ? <span className="link medium-strong prl5" onClick={(e)=>this.resetPassword(e)} style={{color:'#0092ed'}}>Click here</span></p>             
                    </form>
                <Modal/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default withRouter(connect(null, mapDispatchToProps)(Login));