import React, {Component, Fragment} from 'react';
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import Loader from "../Loader";
import TableComponent from "../Common/TableComponent";
import {infiniteScroll, MESSAGES, PATHS} from "../../constants";
import {dateTimeFormatter} from "../../helper";
// import {MODALS} from "../../storeManager/modal/constants";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../storeManager/modal/actions";
import Modal from "../Common/Modal";
import ReactLoading from "react-loading";

class Activities extends Component {
    state = {
        recentActivities: [],
        isLoading: true,
        loadMoreLoader: false,
        hasMoreItems: false,
        rowsCount: infiniteScroll.activityRowCount,
        pageCount: infiniteScroll.pageCount,
        totalCount: 0
    };

    componentDidMount() {
        this.getRecentActivitiesList()
            .catch(() => {
                console.error(MESSAGES.DID_MOUNT_ERROR);
            });
        window.addEventListener("scroll", this.handleScroll);
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const { hasMoreItems, pageCount } = this.state;
        const footerHeight = document.querySelector('footer').offsetHeight;
        if (((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - footerHeight))
            && hasMoreItems) {
            this.setState({pageCount: pageCount + 1, loadMoreLoader: true, hasMoreItems: false});
            this.getRecentActivitiesList()
                .catch(() => {
                    console.error(MESSAGES.DID_MOUNT_ERROR);
                });
        }
    };

    getRecentActivitiesList = async () => {
        const { rowsCount, pageCount, recentActivities } = this.state;
        const payload = {
            "noOfRows": rowsCount,
            "page": pageCount,
        };

        try {
            const response = await commonService.recentActivitiesApi(payload);
            const responseData = response.data;
            if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                let updateData = [...recentActivities];
                let activitiesData = responseData.data.activities;
                // activitiesData = sortBy(activitiesData, 'date' , 'dsc', 'number');
                let filterData = activitiesData.map(columnData => {
                  //transactionIdSequence key used to show Only
                    return {
                        activityType: columnData.activityType,
                        transactionId: <span
                            className="text-btn"
                            onClick={()=>this.openDetailModal(columnData.transactionId, columnData.activityType, columnData.transactionIdSequence)}
                        >
                          {columnData.transactionIdSequence}
                          </span>,
                        date: columnData.date ? dateTimeFormatter(columnData.date) : '-',
                        status: <span className={columnData.status.toLowerCase()}>{columnData.status}</span>,
                        amount: columnData.amount ?
                            <Fragment><span>&#8377;</span> {columnData.amount}</Fragment>
                            : <Fragment><span className="d-none">&#8377;</span></Fragment>,
                        packName: columnData.packName ?
                          columnData.packName : <Fragment><span className="d-none">&#8377;</span></Fragment>
                    }
                });
                updateData = updateData.concat(filterData);
                this.setState({
                    // recentActivities: filterData,
                    recentActivities: updateData,
                    isLoading: false,
                    totalCount: responseData.data.totalCount,
                    loadMoreLoader: false,
                    hasMoreItems: responseData.data.totalCount > updateData.length
                })
            } else {
                this.setState({error: responseData.message, isLoading: false, loadMoreLoader: false, hasMoreItems: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false, loadMoreLoader: false, hasMoreItems: false})
        }
    };

    openDetailModal = (id, activityType, transactionIdSequence) => {
        /*const { openModal } = this.props;
        openModal(MODALS.ACTIVITIES_DETAILS, {transactionId: id, activityType, activityFullWidthPopup: true});*/
      const { history } = this.props;
      history.push({
        pathname: PATHS.ACTIVITY_DETAIL,
        state: {transactionId: id, activityType, transactionIdSequence}
      })
    };

    render() {
        const { isLoading, recentActivities, loadMoreLoader } = this.state;
        return (
            <div className="activities-container">
                {
                    isLoading && <Loader />
                }
                <div className="content-wrapper pb60">
                    <div className="d-flex pt20 pb25">
                        <h2 className="heading text-center">
                            Recent Activities
                        </h2>
                    </div>

                    <TableComponent
                        tableData={recentActivities}
                        tableClass={"recent-activities-table"}
                    />
                    {
                        loadMoreLoader && <div className="d-flex justify-content-center mt20"> <ReactLoading type={'bars'} color={'#67757d'} /> </div>
                    }
                </div>
                <Modal />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(Activities);
