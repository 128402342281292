import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import './service-request.scss';
import RaiseRequest from "./RaiseRequest";
import TrackRequest from "./TrackRequest";

class ServiceRequest extends Component {
    state = {
        activeTab : 0,
        subId:'',
        noOfRows: null
    };

    handleSelect = (index) => {
        this.setState({
            activeTab: index
        })
    };

    componentDidMount() {
        const { history: { location: { state }}} = this.props;
        state && this.setState({subId: state.subId, noOfRows: state.noOfRows});
    }

    render() {
        const { activeTab, subId, noOfRows } = this.state;
        return (
            <div className="service-request">
                <div className="content-wrapper">
                    <div className="tab-strip-row pt10 mb40">
                        <ul className="list-tab">
                            <li
                                className={activeTab === 0 ? 'active' : ''}
                                onClick={() =>this.handleSelect(0)}
                            >
                                Raise a Service Request
                            </li>
                            <li
                                className={activeTab === 1 ? 'active' : ''}
                                onClick={() =>this.handleSelect(1)}
                            >
                                Track Service Request
                            </li>
                        </ul>
                    </div>
                    {
                        activeTab === 0 ? (
                            <RaiseRequest subId={subId} noOfRows={noOfRows} />
                        ) : <TrackRequest subId={subId} noOfRows={noOfRows} />
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(ServiceRequest);