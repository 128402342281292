import React,{Component} from 'react';
import './adminUser.scss';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';

import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Modal from "../../../Common/Modal";
import {MODALS} from '../../../../storeManager/modal/constants';
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import Loader from '../../../Loader';

class AdminUser extends Component {
    state = {
        userList: [],
        isLoading: true,

    }

  componentDidMount(){
    this.getUserList()
  }


  editAdminUser = async (email, name, phone, status, password) => {
      this.setState({isLoading: true});
      const payload = {
          "email": email,
          "name": name,
          "password": password,
          "phone": phone,
          "status": status
      };
      try {
          const response = await commonService.editUser(payload);
          const responseData = response.data;
              this.setState({isLoading: false});
              const {openModal} = this.props;
              if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.getUserList()
              }
              openModal(MODALS.CONFIRMATION_POPUP,
                  {
                      message: responseData.message ,
                      reDirectLinkHide: true,
                      // hideCloseButton: true,
                      redirectLoginPage: true,
                  })
              
      } catch {
          this.setState({error: GlobalConfig.serverError, isLoading: false})
      }
  };

    getUserList = async() => {
        try {
            const response = await commonService.getUserListApi();
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({userList:responseData.data, isLoading:false})
            } else {
              this.setState({error: responseData.message, isLoading: false})
            }              
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }

    }
    editUser = (e, item) => {
      e.preventDefault();
      const {openModal} = this.props;
      openModal(MODALS.ADMIN_EDIT_USER,
          {
              userData: item,
              editUser:true,
              editAdminUser:this.editAdminUser,
          },
          )
    }

    render() {
        const {userList, isLoading} = this.state;
        if(isLoading){
          return <Loader/>
        }
        return (
            <div className="content-wrapper">
            <div className="d-flex justify-content-between mb20">
              <h2 className="heading">
              List of sales user
              </h2>
            </div>
                  
              <table className="table" style={{width:'100%'}}>
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">Status</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
  {userList && userList.length && userList.map((item) => (                  
    <tr key={item.email}>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.status ===1 ? 'Active': 'Inactive'}</td>
      <td><i className="icon"></i> <span className="link" onClick={(e)=>this.editUser(e, item)}>Edit</span></td>
    </tr>
))}
</tbody>
</table>
<Modal/>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      openModal: bindActionCreators(openModalHandler, dispatch),
  }
};

export default connect(null, mapDispatchToProps)(AdminUser);