import React from 'react';
import './error.scss';

const Error = (props) => {
	const { error } = props;

	return (
		<div className="error d-flex justify-content-center">
			<span className="error-msg">
				{error}
			</span>
		</div>
	)
};

export default Error;
