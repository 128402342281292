import React, {Component, Fragment} from 'react';
import socialLinks from './../../../mocks/socialLinks';
import './footer.scss';
import {dateTimeFormatter} from '../../../helper';

class Footer extends Component {
    state = {
        ipAddress: localStorage.getItem("ipAddress") ? `${atob(localStorage.getItem("ipAddress"))}` : "",
        lastLoginTime: (!localStorage.getItem("lastLoginTime") ? 0 : + localStorage.getItem("lastLoginTime")), //+ added to make it a number after decoding
    };

    render() {
        const { ipAddress, lastLoginTime } = this.state;
        return (
            <footer className="footer-container ptb25">
                <div className="wrapper">
                    <div className="inner prl20">
                        <h2 className="heading pb10">
                            Join the Tata Sky family
                        </h2>

                        <ul className="social-connection-list mb40">
                            {
                                socialLinks.map((data,index) =>
                                    <li className="link" key={index}>
                                        <a
                                            className={`icon ${data.styleClass}`}
                                            href={data.url}
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            title={data.title}
                                        >
                                        </a>
                                    </li>
                                )
                            }
                        </ul>

                        <p className="description mb0">
                            "Tata® Tata Sons Limited. All Sky Trademarks, and any intellectual property they
                            contain, are owned by Sky International AG. Used under License by Tata Sky Limited.
                            Order movies ™ and &copy; Tata Sky Limited 2005. All IPR in and to the Website vest with
                            Tata Sky Limited from 2006 onwards."
                        </p>
                    </div>
                </div>
                {
                    ipAddress && lastLoginTime ? (
                        <div className="last-login-info">
                            <div className="last-login">
                                <strong>Last Login: </strong> {dateTimeFormatter(lastLoginTime)}
                            </div>
                            <div className="ip-address">
                                <strong>From IP: </strong> {ipAddress}
                            </div>
                        </div>
                    ) : <Fragment />
                }
            </footer>
        );
    }
}

export default Footer;