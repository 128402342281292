import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {closeModal, openModal as openModalHandler} from "../../../../storeManager/modal/actions";
// import InputsValidator from '../../../../services/FormValidator/InputsValidator';
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import {PATHS} from "../../../../constants";
import Error from "../../../Common/Error";
import {MODALS} from "../../../../storeManager/modal/constants";
import {restrictDecimalInput} from './../../../../helper';
import ReCaptcha from '../../../Common/GoogleCaptcha/googleCaptcha';

class LoginOTP extends Component {
    state = {
        rmn: '',
        otp: '',
        error: '',
        password: '',
        openForgotPasswordControl: false,
        isLoading: false,
        resendOtp: true,
        showPasswordField: true,
        captchaToken: null
    };

    componentDidMount() {
        this.inputFocus.focus();
    }

    otpInputChangeHandler = (event) => {
        let {name, value} = event.target;
        value = value.trim();
        if (name === 'otp') {
            if (!isNaN(value)) {
                this.setState({
                    [name]: value,
                    error: ''
                })
            }
        } else {
            this.setState({
                [name]: value,
                error: ''
            })
        }
    };

    /*validateForgotPasswordEmail = (email) => {
        const {history, closeModal} = this.props;
        const emailErrorObj = InputsValidator.validateEmail(email);
        if (emailErrorObj.error) {
            this.setState({
                error: emailErrorObj.error
            });
        } else {
            this.setState({
                error: ''
            });
            closeModal();
            history.push(PATHS.LOGIN)
        }
    };*/

    validateOTP = async () => {
        const {otp, rmn} = this.state;
      const {modalParameters: {mobileNumber, chainId, userName}, history, closeModal} = this.props;
      const payload = {
        "validateOTP": {
          "mobileNumber": rmn || mobileNumber,
          "otp": otp,
          "subscriberId": chainId, //used to create token
        }
      };
      try {
        const response = await commonService.validateOTPStatus(payload);
        const responseData = response.data;
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
          const userNameKey = responseData.data.name || userName;
          const ipAddress = btoa(responseData.data.ipAddress);
          const lastLoginTime = !responseData.data.lastLoginTime ? 0 : responseData.data.lastLoginTime;
          const name = btoa(userNameKey);
          const userChainId = btoa(chainId);
          localStorage.setItem("key", `${name}`);
          localStorage.setItem("chKeyId", `${userChainId}`);
          localStorage.setItem("ipAddress", `${ipAddress}`);
          localStorage.setItem("lastLoginTime", `${lastLoginTime}`);
          closeModal();
          history.push(PATHS.HOME);
        } else {
          this.setState({error: responseData.message, isLoading: false})
        }
      } catch {
        this.setState({error: GlobalConfig.serverError, isLoading: false})
      }
    };

    validatePassword = async (chainId) => {
        const {password, captchaToken} = this.state;
        const {history, closeModal} = this.props;
        const payload = {
            "subscriberId": chainId,
            "password": password,
            "captchaToken": captchaToken
        };
        try {
            const response = await commonService.validatePasswordStatus(payload);
            const responseData = response.data;
            this.captchaElement &&
            this.captchaElement.reset(this.captchaElementResetId);
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
              const userName = btoa(responseData.data.name);
              const userChainId = btoa(chainId);
              const ipAddress = btoa(responseData.data.ipAddress);
              const lastLoginTime = !responseData.data.lastLoginTime ? 0 : responseData.data.lastLoginTime;
              localStorage.setItem("key", `${userName}`);
              localStorage.setItem("chKeyId", `${userChainId}`);
              localStorage.setItem("ipAddress", `${ipAddress}`);
              localStorage.setItem("lastLoginTime", `${lastLoginTime}`);
                closeModal();
                history.push({
                    pathname: PATHS.HOME,
                    // state: {chainId: chainId}
                });
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.captchaElement &&
            this.captchaElement.reset(this.captchaElementResetId);
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

  sendEmail = async (chainId) => {
      const {captchaToken} = this.state;
        const {openModal} = this.props;
        const payload = {
            "subscriberId": chainId,
            "captchaToken": captchaToken
        };
        try {
            const response = await commonService.sendEmailStatus(payload);
            const responseData = response.data;
            this.captchaElement &&
            this.captchaElement.reset(this.captchaElementResetId);
            openModal(MODALS.CONFIRMATION_POPUP,
                {message: responseData.message, redirectLoginPage: true, hideCloseButton: true})
        } catch {
            this.captchaElement &&
            this.captchaElement.reset(this.captchaElementResetId);
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    toggleState = (key) => () => {
        this.setState(prevState => ({
            [key]: !prevState[key],
            error: '',
            showPasswordField: false
        }));
    };

    generateOtpForRMN = async () => {
        const {modalParameters: {chainId}} = this.props;
        this.setState({error: '', isLoading: true, otp: ''});
        const payload = {
            "generateOTP": {
                "subscriberId": chainId
            }
        };
        try {
            const response = await commonService.generateOtp(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false, rmn: responseData.data, showPasswordField: false}) //resendOtp: responseData.data.resendOtp
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }

    };

    verifyUser = (e) => {
        e.preventDefault();
        const { openForgotPasswordControl, showPasswordField } = this.state;
      this.setState({isLoading: true});
      // const {modalParameters: {chainId}} = this.props;
      if(openForgotPasswordControl || showPasswordField){
          this.onValidateCaptcha();
        // this.sendEmail(chainId);
      } /*else if(showPasswordField){
        this.onValidateCaptcha();
        // this.validatePassword(chainId);
      }*/ else {
        this.validateOTP();
      }
    };

    onCaptchaResolved = captchaToken => {
        const {modalParameters: {chainId}} = this.props;
        const { showPasswordField } = this.state;
        this.setState({ captchaToken });
        if(captchaToken){
            showPasswordField ? this.validatePassword(chainId) : this.sendEmail(chainId);
        }

    };

    onValidateCaptcha = () => {
        // e.preventDefault();
        this.captchaElement.execute(); //this.captchaElementResetId
        // this.validateField();
        // let isValidData = this.validateForm();
        /*if (isValidData && this.captchaElement) {
            this.captchaElement.execute(this.captchaElementResetId);
        }*/
    };

    render() {
        const {otp, error, password, openForgotPasswordControl, isLoading, resendOtp, showPasswordField} = this.state;
        const {otpInputChangeHandler, verifyUser, toggleState, generateOtpForRMN} = this;

        const OTPLink = (
            <div className="text-btn ptb15" onClick={generateOtpForRMN}>
              Resend OTP
            </div>
        );

        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <div className="b2b-modal-header center pb20">
                    <div className="gradient-text">
                        {
                            openForgotPasswordControl ? 'Reset Password' :
                              showPasswordField ? 'Enter Your Password' : 'Enter Your OTP'
                        }
                    </div>
                </div>

                <div className="prl50">
                    <form onSubmit={(e) => verifyUser(e)}>
                        {
                          showPasswordField ? (
                                <Fragment>
                                  <div className="form-element mt5 mb15">
                                    <input type="password"
                                           className="input-element"
                                           placeholder="Password"
                                           name="password"
                                           value={password}
                                           onChange={otpInputChangeHandler}
                                           ref={(input) => {
                                             this.inputFocus = input;
                                           }}
                                           autoComplete="off"
                                    />
                                  </div>
                                  <div className="mb20">
                                    OR <span className="text-btn" onClick={generateOtpForRMN}>Login using OTP</span> sent to your Registered Mobile Number(RMN)
                                  </div>
                                  <span className="d-flex justify-content-end text-btn"
                                        onClick={toggleState("openForgotPasswordControl")}>
                                    Forgot Password?
                                </span>
                                    <ReCaptcha
                                        id="recaptcha"
                                        getRef={ref => {
                                            this.captchaElement = ref;
                                        }}
                                        onChange={this.onCaptchaResolved}
                                        resetId={id => {
                                            this.captchaElementResetId = id;
                                        }}
                                    />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {
                                        openForgotPasswordControl ? (
                                            <Fragment>
                                                <ReCaptcha
                                                    id="recaptcha-forget-password"
                                                    getRef={ref => {
                                                        this.captchaElement = ref;
                                                    }}
                                                    onChange={this.onCaptchaResolved}
                                                    resetId={id => {
                                                        this.captchaElementResetId = id;
                                                    }}
                                                />
                                                <p>
                                                    Please reset your password using Link sent to the e-mail ID registered with your Subscriber ID.
                                                </p>
                                                {/*<p>
                                                    Your password will be reset and sent to the e-mail ID registered with your Subscriber ID.
                                                </p>*/}
                                            </Fragment>
                                        ) : (
                                          <Fragment>
                                            <div className="form-element">
                                              <input type="text"
                                                     className="input-element otp-pwd"
                                                     placeholder="OTP"
                                                     maxLength="6"
                                                     name="otp"
                                                     value={otp}
                                                     onChange={otpInputChangeHandler}
                                                     ref={(input) => {
                                                       this.inputFocus = input;
                                                     }}
                                                     autoComplete="off"
                                                     onKeyPress={restrictDecimalInput}
                                              />
                                            </div>
                                            {resendOtp ? OTPLink : null}
                                          </Fragment>
                                        )
                                    }
                                </Fragment>
                            )
                        }

                        {
                            error && <Error error={error}/>
                        }

                        <button
                            type="submit"
                            className="button fill big w-140 mt10 mb20"
                            onClick={(e) => verifyUser(e)}
                            disabled={
                              showPasswordField ?
                                (!password) : openForgotPasswordControl ? false : (!otp || otp.length < 6)
                            }
                        >
                            {
                                openForgotPasswordControl ? 'Reset' : 'Verify'
                            }
                        </button>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(LoginOTP));
