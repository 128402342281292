import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import Loader from "../../Loader";
import TableComponent from "../../Common/TableComponent";
import {dateTimeFormatter} from '../../../helper';
import {MESSAGES} from "../../../constants";

class TrackRequest extends Component {
    state = {
        trackRequest: [],
        isLoading: true
    };

    componentDidMount() {
        this.getServiceRequestData()
            .catch(() => {
                console.error(MESSAGES.DID_MOUNT_ERROR);
            });
    }

    getServiceRequestData = async () => {
        const { subId, noOfRows } = this.props;
        const payload = {
            "subscriberId": subId || '',
            noOfRows
        };
        try {
            const response = await commonService.serviceRequestQueryApi(payload);
            const responseData = response.data;
            if(responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                let trackData = responseData.data;
                const filterData= trackData.map(columnData => {
                    return {
                        srNumber: columnData.srNumber,
                        srOpenDate: columnData.srOpenDate ? dateTimeFormatter(columnData.srOpenDate) : '-',
                        srType: columnData.srType,
                        srArea: columnData.srArea,
                        srDueDate: columnData.srDueDate? dateTimeFormatter(columnData.srDueDate) : '-',
                        status: columnData.status
                    }
                });
                this.setState({
                    trackRequest: filterData,
                    isLoading: false
                })
            } else {
                this.setState({error: responseData.message,isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError,isLoading:false})
        }
    };

    render() {
        const { isLoading, trackRequest } = this.state;

        return (
            <div className="SRTrack-request-container">
                {
                    isLoading && <Loader />
                }

                <div className="pb60">
                    <TableComponent
                        tableData={trackRequest}
                        tableClass={"SRTrack-request-table"}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(TrackRequest);